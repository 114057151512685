export default {
    'WELCOME': 'Welcome',
    'TICKETS': 'Tickets',
    'ARTICLES': 'Articles',
    'ACCOUNT': 'Account',
    'SUBMIT': 'Submit',
    'EMAIL': 'Email',
    'PASSWORD': 'Password',
    'REPEAT_PASSWORD': 'Repeat password',
    'LOG_IN': 'Log in',
    'SIGN_UP': 'Sign up',
    'FORGOT_PASSWORD': 'Forgot your password?',
    'RECOVER_PASSWORD': 'Recover Password',
    'RECOVER_SENT': 'An email with recover instructions has been sent.',
    'NEW_EMAIL': 'New email',
    'FULL_NAME': 'Minecraft name',
    'OLD_PASSWORD': 'Old password',
    'NEW_PASSWORD': 'New password',
    'REPEAT_NEW_PASSWORD': 'Repeat new password',
    'BACK_LOGIN_FORM': 'Back to login form',
    'VIEW_ARTICLES': 'View Articles',
    'EDIT_PROFILE': 'Edit Profile',
    'CLOSE_SESSION': 'Close session',
    'CREATE_TICKET': 'Create Ticket',
    'TICKET_LIST': 'Ticket List',
    'SUPPORT_CENTER': 'Support Center',
    'DEPARTMENT': 'Server',
    'AUTHOR': 'Author',
    'DATE': 'Date',
    'RESPOND': 'Respond',
    'RESPOND_TICKET': 'Respond Ticket',
    'CLOSE_TICKET': 'Close ticket',
    'DELETE_TICKET': 'Delete ticket',
    'NO_ATTACHMENT': 'No file attachment',
    'STAFF': 'Staff',
    'CUSTOMER': 'Customer',
    'YES': 'Yes',
    'NO': 'No',
    'CANCEL': 'Cancel',
    'MY_ACCOUNT': 'My Account',
    'DASHBOARD': 'Dashboard',
    'USERS': 'Users',
    'SETTINGS': 'Settings',
    'STATISTICS': 'Statistics',
    'LAST_ACTIVITY': 'Last Activity',
    'MY_TICKETS': 'My Tickets',
    'NEW_TICKETS': 'New Tickets',
    'ALL_TICKETS': 'All Tickets',
    'CUSTOM_RESPONSES': 'Custom Responses',
    'LIST_USERS': 'List Users',
    'BAN_USERS': 'Ban Users',
    'LIST_ARTICLES': 'Article List',
    'STAFF_MEMBERS': 'Staff Members',
    'DEPARTMENTS': 'Departments',
    'SYSTEM_PREFERENCES': 'System Preferences',
    'ADVANCED_SETTINGS': 'Advanced Settings',
    'EMAIL_TEMPLATES': 'Email Templates',
    'FILTERS_CUSTOM_FIELDS': 'Filters and Custom Fields',
    'PRIORITY': 'Priority',
    'NUMBER': 'Number',
    'HIGH': 'High',
    'MEDIUM': 'Medium',
    'LOW': 'Low',
    'TITLE': 'Title',
    'CONTENT': 'Content',
    'SAVE': 'Save',
    'DISCARD_CHANGES': 'Discard changes',
    'DELETE': 'Delete',
    'LANGUAGE': 'Language',
    'OWNER': 'Owner',
    'OWNED': 'Owned',
    'STATUS': 'Status',
    'NONE': 'None',
    'OPENED': 'Opened',
    'CLOSED': 'Closed',
    'CLOSE': 'Close',
    'RE_OPEN': 'Re open',
    'ASSIGN_TO_ME': 'Assign to me',
    'UN_ASSIGN': 'Unassign',
    'VIEW_TICKET': 'View Ticket',
    'VIEW_TICKET_DESCRIPTION': 'Check the status of your ticket using your ticket number and email.',
    'SELECT_CUSTOM_RESPONSE': 'Select a custom response...',
    'WARNING': 'Warning',
    'INFO': 'Information',
    'ALL_DEPARTMENTS': 'All Departments',
    'EMAIL_BANNED': 'Email banned',
    'UN_BAN': 'Disable ban',
    'BAN_NEW_EMAIL': 'Ban new email',
    'BAN_EMAIL': 'Ban email',
    'EDIT_EMAIL': 'Edit email',
    'EDIT_PASSWORD': 'Edit password',
    'CHANGE_EMAIL': 'Change email',
    'CHANGE_PASSWORD': 'Change password',
    'NAME': 'Name',
    'SIGNUP_DATE': 'Sign up date',
    'SEARCH_USERS': 'Search users...',
    'SEARCH_EMAIL': 'Search email...',
    'USER_VIEW_TITLE': 'User #{userId}',
    'EDIT_TOPIC': 'Edit Topic',
    'ADD_TOPIC': 'Add Topic',
    'ICON': 'Icon',
    'COLOR': 'Color',
    'ADD_NEW_ARTICLE': 'Add new article',
    'ADD_ARTICLE': 'Add article',
    'LAST_EDITED_IN': 'Last edited in {date}',
    'EDIT': 'Edit',
    'NO_RESULTS': 'No results',
    'DELETE_AND_BAN': 'Delete and ban',
    'STAFF_LEVEL': 'Staff Level',
    'ASSIGNED': 'Assigned',
    'ASSIGNED_TICKETS': '{tickets} assigned tickets',
    'CLOSED_TICKETS': '{tickets} closed tickets',
    'LAST_LOGIN': 'Last login',
    'ADD_NEW_STAFF': 'Add new staff',
    'ADD_STAFF': 'Add staff',
    'LEVEL': 'Level',
    'LEVEL_1': 'Level 1 (Tickets)',
    'LEVEL_2': 'Level 2 (Tickets + Articles)',
    'LEVEL_3': 'Level 3 (Tickets + Articles + Staff)',
    'LEVEL_1_DESCRIPTION': 'can only respond tickets and manage users.',
    'LEVEL_2_DESCRIPTION': 'can do every Level 1 does, can create or edit articles and it can create custom responses.',
    'LEVEL_3_DESCRIPTION': 'can do every Level 2 does, can create or edit staff members and can manage the whole system.',
    'UPDATE_EMAIL': 'Update email',
    'UPDATE_PASSWORD': 'Update password',
    'UPDATE_LEVEL': 'Update level',
    'UPDATE_DEPARTMENTS': 'Update departments',
    'EDIT_STAFF': 'Edit staff member',
    'ADD_DEPARTMENT': 'Add department',
    'UPDATE_DEPARTMENT': 'Update department',
    'TRANSFER_TICKETS_TO': 'Transfer tickets to',
    'COMMENTS': 'Comments',
    'DELETE_STAFF_MEMBER': 'Delete staff member',
    'MAINTENANCE_MODE': 'Maintenance mode',
    'MAINTENANCE_MODE_INFO': 'It will temporary disable the system for regular users.',
    'RECOVER_DEFAULT': 'Recover default',
    'SUPPORT_CENTER_URL': 'Support Center URL',
    'SUPPORT_CENTER_TITLE': 'Support Center Title',
    'SUPPORT_CENTER_LAYOUT': 'Support Center Layout',
    'DEFAULT_TIMEZONE': 'Default Timezone (GMT)',
    'NOREPLY_EMAIL': 'Noreply Email',
    'SMTP_USER': 'SMTP User',
    'SMTP_SERVER': 'SMTP Server',
    'SMTP_PASSWORD': 'SMTP Password',
    'PORT': 'Port',
    'RECAPTCHA_PUBLIC_KEY': 'Recaptcha Public Key',
    'RECAPTCHA_PRIVATE_KEY': 'Recaptcha Private Key',
    'ALLOW_FILE_ATTACHMENTS': 'Allow file attachments',
    'MAX_SIZE_MB': 'Max Size (MB)',
    'UPDATE_SETTINGS': 'Update settings',
    'DEFAULT_LANGUAGE': 'Default Language',
    'SUPPORTED_LANGUAGES': 'Supported Languages',
    'SUPPORTED_LANGUAGES_INFO': 'Supported languages are the languages that tickets can be written in.',
    'ALLOWED_LANGUAGES': 'Allowed Languages',
    'ALLOWED_LANGUAGES_INFO': 'Allowed languages are the languages that can be used by an user.',
    'SETTINGS_UPDATED': 'Settings have been updated',
    'ON': 'On',
    'OFF': 'Off',
    'BOXED': 'Boxed',
    'FULL_WIDTH': 'Full width',
    'LOAD_MORE': 'Load More',
    'MY_NOTIFICATIONS': 'My notifications',
    'ALL_NOTIFICATIONS': 'All notifications',
    'VERIFY_SUCCESS': 'User verified',
    'VERIFY_FAILED': 'Could not verify',
    'ENABLE_USER_SYSTEM': 'Use user system for customers',
    'ENABLE_USER_REGISTRATION': 'Enable user registration',
    'INCLUDE_USERS_VIA_CSV': 'Include users via CSV file',
    'BACKUP_DATABASE': 'Backup database',
    'DELETE_ALL_USERS': 'Delete all users',
    'PLEASE_CONFIRM_PASSWORD': 'Please confirm your password to make these changes',
    'REGISTRATION_API_KEYS': 'Registration API keys',
    'NAME_OF_KEY': 'Name of key',
    'KEY': 'Key',
    'ADD_API_KEY': 'Add API Key',
    'NO_KEY_SELECTED': 'No Key selected',
    'CHECK_TICKET': 'Check Ticket',
    'ACTIVITY': 'Activity',
    'HOME': 'Home',
    'TICKET_NUMBER': 'Ticket number',
    'NEXT': 'Next',
    'SUBJECT': 'Subject',
    'SEND_EMAIL_ON_NEW_TICKET': 'Send email on new ticket',
    'STAFF_UPDATED': 'Staff member has been updated',
    'UPDATE': 'Update',
    'NEVER': 'Never',
    'HIMSELF': 'himself',
    'ADD_USER': 'Add user',
    'UPLOAD_FILE': 'Upload file',
    'PRIVATE': 'Private',
    'ENABLE_USER': 'Enable User',
    'DISABLE_USER': 'Disable User',
    'SHOW_CLOSED_TICKETS': 'Show Closed Tickets',
    'IMAGE_HEADER_URL': 'Image header URL',

    'CHART_CREATE_TICKET': 'Tickets created',
    'CHART_CLOSE': 'Tickets closed',
    'CHART_SIGNUP': 'Signups',
    'CHART_COMMENT': 'Replies',
    'CHART_ASSIGN': 'Assigned',

    //ACTIVITIES
    'ACTIVITY_COMMENT': 'commented ticket',
    'ACTIVITY_ASSIGN': 'assigned ticket',
    'ACTIVITY_UN_ASSIGN': 'unassigned ticket',
    'ACTIVITY_CLOSE': 'closed ticket',
    'ACTIVITY_CREATE_TICKET': 'created ticket',
    'ACTIVITY_RE_OPEN': 'reopened ticket',
    'ACTIVITY_DEPARTMENT_CHANGED': 'changed department of ticket',
    'ACTIVITY_PRIORITY_CHANGED': 'changed priority of ticket',

    'ACTIVITY_EDIT_SETTINGS': 'edited settings',
    'ACTIVITY_SIGNUP': 'signed up',
    'ACTIVITY_ADD_TOPIC': 'added topic',
    'ACTIVITY_ADD_ARTICLE': 'added article',
    'ACTIVITY_DELETE_TOPIC': 'deleted topic',
    'ACTIVITY_DELETE_ARTICLE': 'deleted article',
    'ACTIVITY_EDIT_ARTICLE': 'edited article',
    'ACTIVITY_ADD_STAFF': 'added staff',
    'ACTIVITY_ADD_DEPARTMENT': 'added department',
    'ACTIVITY_DELETE_DEPARTMENT': 'deleted department',
    'ACTIVITY_EDIT_DEPARTMENT': 'edited department',
    'ACTIVITY_ADD_CUSTOM_RESPONSE': 'added custom response',
    'ACTIVITY_DELETE_CUSTOM_RESPONSE': 'deleted custom response',
    'ACTIVITY_EDIT_CUSTOM_RESPONSE': 'edited custom response',
    'ACTIVITY_BAN_USER': 'banned user',
    'ACTIVITY_DELETE_USER': 'deleted user',
    'ACTIVITY_UN_BAN_USER': 'unbanned user',

    'SERVER_REQUIREMENTS': 'Server requirements',
    'DATABASE_CONFIGURATION': 'Database configuration',
    'ADMIN_SETUP': 'Admin setup',
    'COMPLETED': 'Completed',
    'INSTALL_HEADER_TITLE': 'OpenSupports Installation Wizard',
    'INSTALL_HEADER_DESCRIPTION': 'This wizard will help you to configure and install OpenSupports on your website',
    'SELECT_LANGUAGE': 'Select language',
    'REQUIREMENT': 'Requirement',
    'VALUE': 'Value',
    'REFRESH': 'Refresh',
    'USER_SYSTEM': 'User System',
    'PREVIOUS': 'Previous',
    'DATABASE_HOST': 'MySQL server',
    'DATABASE_PORT': 'MySQL server port',
    'DATABASE_NAME': 'MySQL database name',
    'DATABASE_USER': 'MySQL user',
    'DATABASE_PASSWORD': 'MySQL password',
    'ADMIN_NAME': 'Admin account name',
    'ADMIN_EMAIL': 'Admin account email',
    'ADMIN_PASSWORD': 'Admin account password',
    'ADMIN_PASSWORD_DESCRIPTION': 'Please remember this password. It is needed for accessing the admin panel. You can change it later.',
    'INSTALLATION_COMPLETED': 'Installation completed.',
    'INSTALLATION_COMPLETED_DESCRIPTION': 'The installation of OpenSupports is completed. Redirecting to admin panel...',

    'STEP_TITLE': 'Step {current} of {total} - {title}',
    'STEP_1_DESCRIPTION': 'Select your preferred language for the installation wizard.',
    'STEP_2_DESCRIPTION': 'Here are listed the requirements for running OpenSupports. Please make sure that all requirements are satisfied.',
    'STEP_3_DESCRIPTION': 'Please fill the MySQL database configuration.',
    'STEP_4_DESCRIPTION': 'Please select your user system preferences.',
    'STEP_5_DESCRIPTION': 'Please fill your general system preferences.',
    'STEP_6_DESCRIPTION': 'Please configure the administrator account.',
    'STEP_7_DESCRIPTION': 'Installation is completed.',

    //VIEW DESCRIPTIONS
    'CREATE_TICKET_DESCRIPTION': 'This is a form for creating tickets. Fill the form and send us your issues/doubts/suggestions. Our support system will answer it as soon as possible.',
    'TICKET_LIST_DESCRIPTION': 'Here you can find a list of all tickets you have sent to our support team.',
    'TICKETS_DESCRIPTION': 'Send ticket through our support center and get response of your doubts, suggestions and issues.',
    'ARTICLES_DESCRIPTION': 'Take a look to our articles about common issues, guides and documentation.',
    'ACCOUNT_DESCRIPTION': 'All your tickets are stored in your account\'s profile. Keep track of all your tickets you send to our staff team.',
    'SUPPORT_CENTER_DESCRIPTION': 'Welcome to our support center. You can contact us through a tickets system. Your tickets will be answered by our staff.',
    'CUSTOM_RESPONSES_DESCRIPTION': 'Custom responses are automated responses for common problems',
    'MY_TICKETS_DESCRIPTION': 'Here you can view the tickets you are responsible for.',
    'NEW_TICKETS_DESCRIPTION': 'Here you can view all the new tickets that are not assigned by anyone.',
    'ALL_TICKETS_DESCRIPTION': 'Here you can view the tickets of the departments you are assigned.',
    'TICKET_VIEW_DESCRIPTION': 'This ticket has been sent by a customer. Here you can respond or assign the ticket',
    'BAN_USERS_DESCRIPTION': 'Here you can see a list of banned emails, you can un-ban them or add more emails to the list.',
    'LIST_USERS_DESCRIPTION': 'This is the list of users that are registered in this platform. You can search for someone in particular, delete it or ban it.',
    'USER_VIEW_DESCRIPTION': 'Here you can find all the information about an user and all the tickets sent by the user. You can also delete or ban it.',
    'DELETE_USER_DESCRIPTION': 'The user will not be able to log in aging and all its tickets will be erased. Also, the email can not be used any more.',
    'DELETE_TOPIC_DESCRIPTION': 'By deleting the topic, all articles on it will be erased.',
    'EDIT_TOPIC_DESCRIPTION': 'Here you can change the name, the icon and the icon color of the topic.',
    'ADD_ARTICLE_DESCRIPTION': 'Here you can add an article that will be available for every user. It will be added inside the category {category}.',
    'LIST_ARTICLES_DESCRIPTION': 'This is a list of articles that includes information about our services.',
    'ADD_TOPIC_DESCRIPTION': 'Here you can add a topic that works as a category for articles.',
    'DELETE_ARTICLE_DESCRIPTION': 'You\'re going to delete this article forever.',
    'STAFF_MEMBERS_DESCRIPTION': 'Here you can see who are your staff members.',
    'ADD_STAFF_DESCRIPTION': 'Here you can add staff members to your teams.',
    'EDIT_STAFF_DESCRIPTION': 'Here you can edit information about a staff member.',
    'MY_ACCOUNT_DESCRIPTION': 'Here you can edit information about you.',
    'DEPARTMENTS_DESCRIPTION': 'A department is a group where the tickets can go. They are used to categorize the tickets. You can assign them to other staff members.',
    'MAINTENANCE_MODE_DESCRIPTION': 'The support system is in maintenance mode, thus unavailable at the moment. We will come back as soon as possible.',
    'EMAIL_TEMPLATES_DESCRIPTION': 'Here you can edit the templates of the emails that will be sent to users. Remember that the double brackets curly braces indicate a variable value. For example, \'name\' represents the user\'s name.',
    'SYSTEM_PREFERENCES_DESCRIPTION': 'Here you can edit the preferences of the system.',
    'VERIFY_SUCCESS_DESCRIPTION': 'You user has been verified correctly. You can log in now.',
    'VERIFY_FAILED_DESCRIPTION': 'The verification could not be done.',
    'STATISTICS_DESCRIPTION': 'Here you can view statistics related to tickets and signups.',
    'ADVANCED_SETTINGS_DESCRIPTION': 'Here you can change the advanced settings of your system. Please be careful, the changes you make can not be reversed.',
    'USER_SYSTEM_DISABLED': 'User system has been disabled',
    'USER_SYSTEM_ENABLED': 'User system has been enabled',
    'REGISTRATION_DISABLED': 'Registration has been disabled',
    'REGISTRATION_ENABLED': 'Registration has been enabled',
    'ADD_API_KEY_DESCRIPTION': 'Insert the name and a registration api key will be generated.',
    'SIGN_UP_VIEW_DESCRIPTION': 'Here you can create an account for our support center. It is required for send tickets and see documentation.',
    'EDIT_PROFILE_VIEW_DESCRIPTION': 'Here you can edit your user by changing your email or your password.',
    'ENABLE_USER_SYSTEM_DESCRIPTION': 'Enable/disable the use of an user system. If you disable it, all users will be deleted but the tickets will be kept. If you enable it, the users of existent tickets will be created.',
    'CSV_DESCRIPTION': 'The CSV file must have 3 columns: email, password, name. There is no limit in row count. It will be created one user per row in the file.',
    'SMTP_SERVER_DESCRIPTION': 'The configuration of the SMTP server allows the application to send mails. If you do not configure it, no emails will be sent by OpenSupports.',
    'ENABLE_USER_DESCRIPTION': 'This action allows the user to sign in and create tickets.',
    'DISABLE_USER_DESCRIPTION': 'User will be disabled and will not be able to sign in and create tickets.',
    'PRIVATE_RESPONSE_DESCRIPTION': 'This response will only be seen by staff members',
    'PRIVATE_TOPIC_DESCRIPTION': 'This topic will only be seen by staff members',
    'PRIVATE_DEPARTMENT_DESCRIPTION': 'This department will only be seen by staff members',

    //ERRORS
    'EMAIL_OR_PASSWORD': 'Email or password invalid',
    'EMAIL_NOT_EXIST': 'Email does not exist',
    'ERROR_EMPTY': 'Invalid value',
    'ERROR_PASSWORD': 'Invalid password',
    'ERROR_NAME': 'Invalid name',
    'ERROR_TITLE': 'Invalid title',
    'ERROR_EMAIL': 'Invalid email',
    'ERROR_CONTENT_SHORT': 'Content too short',
    'PASSWORD_NOT_MATCH': 'Password does not match',
    'INVALID_RECOVER': 'Invalid recover data',
    'TICKET_SENT_ERROR': 'An error occurred while trying to create the ticket.',
    'TICKET_COMMENT_ERROR': 'An error occurred while trying to add the comment.',
    'NO_PERMISSION': 'You\'ve no permission to access to this page.',
    'INVALID_USER': 'User id is invalid',
    'ERROR_RETRIEVING_TICKETS': 'An error occurred while trying to retrieve tickets.',
    'ERROR_RETRIEVING_USERS': 'An error occurred while trying to retrieve users.',
    'ERROR_RETRIEVING_BAN_LIST': 'An error occurred while trying to retrieve the list of banned emails.',
    'ERROR_BANNING_EMAIL': 'An error occurred while trying to ban the email.',
    'ERROR_RETRIEVING_ARTICLES': 'An error occurred while trying to retrieve articles.',
    'ERROR_LIST': 'Select at least one',
    'ERROR_URL': 'Invalid URL',
    'UNVERIFIED_EMAIL': 'Email is not verified yet',
    'ERROR_UPDATING_SETTINGS': 'An error occurred while trying to update settings',
    'INVALID_EMAIL_OR_TICKET_NUMBER': 'Invalid email or ticket number',
    'INVALID_FILE': 'Invalid file',
    'ERRORS_FOUND': 'Errors found',
    'ERROR_IMAGE_SIZE': 'No image can have a size greater than {size} MB',
    'USER_DISABLED': 'This account is disabled.',
    'INVALID_SYNTAX': 'Invalid syntax.',
    'DEPARTMENT_PRIVATE_TICKETS': 'This department has tickets created by non-staff and it can not be private',

    //MESSAGES
    'SIGNUP_SUCCESS': 'You have registered successfully. Please check your email.',
    'TICKET_SENT': 'Ticket has been created successfully.',
    'VALID_RECOVER': 'Password recovered successfully',
    'EMAIL_EXISTS': 'Email already exists',
    'ARE_YOU_SURE': 'Confirm action',
    'EMAIL_WILL_CHANGE': 'The current email will be changed',
    'PASSWORD_WILL_CHANGE': 'The current password will be changed',
    'EMAIL_CHANGED': 'Email has been changed successfully',
    'PASSWORD_CHANGED': 'Password has been changed successfully',
    'OLD_PASSWORD_INCORRECT': 'Old password is incorrect',
    'WILL_LOSE_CHANGES': 'You haven\'t save. Your changes will be lost.',
    'WILL_DELETE_CUSTOM_RESPONSE': 'The custom response will be deleted.',
    'WILL_DELETE_DEPARTMENT': 'The department will be deleted. All the tickets will be transfer to the department selected.',
    'NO_STAFF_ASSIGNED': 'No staff member is assigned to this department.',
    'NO_DEPARTMENT_ASSIGNED': 'No ticket department is assigned you.',
    'LEVEL_UPDATED': 'Level has been updated successfully.',
    'DEPARTMENTS_UPDATED': 'Departments have been updated successfully.',
    'FAILED_EDIT_STAFF': 'An error occurred while trying to edit staff member.',
    'EMAIL_BANNED_SUCCESSFULLY': 'Email has been banned successfully',
    'WILL_DELETE_STAFF': 'This staff member will be deleted and all its tickets will be unassigned.',
    'WILL_RECOVER_EMAIL_TEMPLATE': 'This email template will be recover to it\'s default value on this language.',
    'SUCCESS_IMPORTING_CSV_DESCRIPTION': 'CSV File has been imported successfully',
    'SUCCESS_DELETING_ALL_USERS': 'Users have beend deleted successfully',

    'LAST_7_DAYS': 'Last 7 days',
    'LAST_30_DAYS': 'Last 30 days',
    'LAST_90_DAYS': 'Last 90 days',
    'LAST_365_DAYS': 'Last 365 days',

    'ACTIVITY_COMMENT_THIS': 'commented this ticket',
    'ACTIVITY_ASSIGN_THIS': 'assigned this ticket to',
    'ACTIVITY_UN_ASSIGN_THIS': 'unassigned this ticket to',
    'ACTIVITY_CLOSE_THIS': 'closed this ticket',
    'ACTIVITY_CREATE_TICKET_THIS': 'created this ticket',
    'ACTIVITY_RE_OPEN_THIS': 'reopened this ticket',
    'ACTIVITY_DEPARTMENT_CHANGED_THIS': 'changed department of this ticket to ',
    'ACTIVITY_PRIORITY_CHANGED_THIS': 'changed priority of this ticket to',
    'DATE_PREFIX': 'on',
    'LEFT_EMPTY_DATABASE': 'Leave empty for automatic database creation',
    'DEFAULT_PORT': 'Leave empty for 3306 as default',
    'REMEMBER_ME': 'Remember me',
    'EMAIL_LOWERCASE': 'email',
    'PASSWORD_LOWERCASE': 'password',
    'TEST_SMTP_CONNECTION': 'Test SMTP connection',
    'SMTP_CONNECTION_SUCCESS': 'SMTP credentials are valid.',
    'SMTP_CONNECTION_ERROR': 'Can\'t connect to SMTP server.'
};
