export default {
    'WELCOME': 'добро пожаловать',
    'TICKETS': 'Билеты',
    'ARTICLES': 'Статьи',
    'ACCOUNT': 'счет',
    'SUBMIT': 'Отправить',
    'EMAIL': 'Эл. адрес',
    'PASSWORD': 'пароль',
    'REPEAT_PASSWORD': 'Повторите пароль',
    'LOG_IN': 'Авторизоваться',
    'SIGN_UP': 'зарегистрироваться',
    'FORGOT_PASSWORD': 'Забыли пароль?',
    'RECOVER_PASSWORD': 'Восстановить пароль',
    'RECOVER_SENT': 'Электронное письмо с инструкциями восстановления был отправлен.',
    'NEW_EMAIL': 'Новый E-mail',
    'FULL_NAME': 'Полное имя',
    'OLD_PASSWORD': 'Старый пароль',
    'NEW_PASSWORD': 'Новый пароль',
    'REPEAT_NEW_PASSWORD': 'повторите новый пароль',
    'BACK_LOGIN_FORM': 'Вернуться к форме входа в систему',
    'VIEW_ARTICLES': 'Просмотр статей',
    'EDIT_PROFILE': 'Редактировать профиль',
    'CLOSE_SESSION': 'Закрыть сессию',
    'CREATE_TICKET': 'Создать билет',
    'TICKET_LIST': 'Список билетов',
    'SUPPORT_CENTER': 'Центр поддержки',
    'DEPARTMENT': 'отдел',
    'AUTHOR': 'автор',
    'DATE': 'Дата',
    'RESPOND': 'Отвечать',
    'RESPOND_TICKET': 'Ответить билет',
    'CLOSE_TICKET': 'закрыть билет',
    'DELETE_TICKET': 'Удалить билет',
    'NO_ATTACHMENT': 'Нет вложений файлов',
    'STAFF': 'Сотрудники',
    'CUSTOMER': 'Клиент',
    'YES': 'да',
    'NO': 'Нет',
    'CANCEL': 'Отмена',
    'MY_ACCOUNT': 'Мой аккаунт',
    'DASHBOARD': 'Панель приборов',
    'USERS': 'пользователей',
    'SETTINGS': 'настройки',
    'STATISTICS': 'Статистика',
    'LAST_ACTIVITY': 'Последняя активность',
    'MY_TICKETS': 'Мои билеты',
    'NEW_TICKETS': 'Новые билеты',
    'ALL_TICKETS': 'Все билеты',
    'CUSTOM_RESPONSES': 'персонализированных ответы',
    'LIST_USERS': 'Список пользователей',
    'BAN_USERS': 'заблокированные пользователи',
    'LIST_ARTICLES': 'Список статей',
    'STAFF_MEMBERS': 'Штатные сотрудники',
    'DEPARTMENTS': 'ведомства',
    'SYSTEM_PREFERENCES': 'Системные настройки',
    'ADVANCED_SETTINGS': 'Расширенные настройки',
    'EMAIL_TEMPLATES': 'Шаблоны электронных сообщений',
    'FILTERS_CUSTOM_FIELDS': 'Фильтры и настраиваемых полей',
    'PRIORITY': 'приоритет',
    'NUMBER': 'Число',
    'HIGH': 'Высокая',
    'MEDIUM': 'средний',
    'LOW': 'Низкий',
    'TITLE': 'заглавие',
    'CONTENT': 'содержание',
    'SAVE': 'Сохранить',
    'DISCARD_CHANGES': 'Отменить изменения',
    'DELETE': 'Удалить',
    'LANGUAGE': 'язык',
    'OWNER': 'владелец',
    'OWNED': 'Подержанный',
    'STATUS': 'Положение дел',
    'NONE': 'никто',
    'OPENED': 'открытый',
    'CLOSED': 'Закрыто',
    'CLOSE': 'Закрыть',
    'RE_OPEN': 'Re open',
    'ASSIGN_TO_ME': 'Назначают мне',
    'UN_ASSIGN': 'Отмена назначения',
    'VIEW_TICKET': 'Просмотр билетов',
    'VIEW_TICKET_DESCRIPTION': 'Проверьте статус своего билета, используя свой номер билета и адрес электронной почты.',
    'SELECT_CUSTOM_RESPONSE': 'Выберите пользовательский ответ ...',
    'WARNING': 'Предупреждение',
    'INFO': 'Информация',
    'ALL_DEPARTMENTS': 'Все отделы',
    'EMAIL_BANNED': 'адрес электронной почты заблокирован',
    'UN_BAN': 'Отключить запрет',
    'BAN_NEW_EMAIL': 'Запрет новых сообщений электронной почты',
    'BAN_EMAIL': 'Запрет по электронной почте',
    'EDIT_EMAIL': 'Изменить адрес эл. Почты',
    'EDIT_PASSWORD': 'Изменить пароль',
    'CHANGE_EMAIL': 'Сменить имейл',
    'CHANGE_PASSWORD': 'Изменить пароль',
    'NAME': 'имя',
    'SIGNUP_DATE': 'информация о регистрации',
    'SEARCH_USERS': 'Поиск пользователей ...',
    'SEARCH_EMAIL': 'Поиск по электронной почте...',
    'USER_VIEW_TITLE': 'пользователь #{userId}',
    'EDIT_TOPIC': 'Редактировать тему',
    'ADD_TOPIC': 'добавить предмет',
    'ICON': 'Значок',
    'COLOR': 'цвет',
    'ADD_NEW_ARTICLE': 'Добавить новую статью',
    'ADD_ARTICLE': ' Добавить статью',
    'LAST_EDITED_IN': 'Last edited in {date}',
    'EDIT': 'редактировать',
    'NO_RESULTS': 'Нет результатов',
    'DELETE_AND_BAN': 'Удалить и запретить',
    'STAFF_LEVEL': 'Уровень персонала',
    'ASSIGNED': 'назначенный',
    'ASSIGNED_TICKETS': '{tickets} assigned tickets',
    'CLOSED_TICKETS': '{tickets} closed tickets',
    'LAST_LOGIN': 'Последний Войти',
    'ADD_NEW_STAFF': 'Добавление новых сотрудников',
    'ADD_STAFF': 'Добавить персонал',
    'LEVEL': 'уровень',
    'LEVEL_1': 'Уровень 1 (Билеты)',
    'LEVEL_2': 'Уровень 2 (Билеты + Статьи)',
    'LEVEL_3': 'Уровень 3 (билеты + Статьи + персонал)',
    'LEVEL_1_DESCRIPTION': 'может отвечать только билеты и управлять пользователями.',
    'LEVEL_2_DESCRIPTION': 'может сделать каждый 1-й уровень делает, позволяет создавать и редактировать статьи и он может создавать собственные ответы.',
    'LEVEL_3_DESCRIPTION': 'может сделать каждый уровень 2 делает, может создавать или редактировать пользователей, персонала и может управлять всей системой.',
    'UPDATE_EMAIL': 'Обновление электронной почты',
    'UPDATE_PASSWORD': 'Обновление пароля',
    'UPDATE_LEVEL': 'уровень обновления',
    'UPDATE_DEPARTMENTS': 'Обновление отделов',
    'EDIT_STAFF': 'сотрудник Редактировать',
    'ADD_DEPARTMENT': 'Добавить отдел',
    'UPDATE_DEPARTMENT': 'отдел обновления',
    'TRANSFER_TICKETS_TO': 'Передача билетов на',
    'COMMENTS': 'Комментарии',
    'DELETE_STAFF_MEMBER': 'Удалить сотрудника',
    'MAINTENANCE_MODE': 'Режим обслуживания',
    'MAINTENANCE_MODE_INFO': 'Это будет временное отключение системы для обычных пользователей.',
    'RECOVER_DEFAULT': 'Восстановить значение по умолчанию',
    'SUPPORT_CENTER_URL': 'URL Центр поддержки',
    'SUPPORT_CENTER_TITLE': 'Центр поддержки Название',
    'SUPPORT_CENTER_LAYOUT': 'Центр поддержки Layout',
    'DEFAULT_TIMEZONE': 'Часовой пояс по умолчанию (GMT)',
    'NOREPLY_EMAIL': ' без ответа по электронной почте',
    'SMTP_USER': 'SMTP Пользователь',
    'SMTP_SERVER': 'SMTP-сервер',
    'SMTP_PASSWORD': 'Пароль SMTP',
    'PORT': 'порт',
    'RECAPTCHA_PUBLIC_KEY': 'Recaptcha Открытый ключ',
    'RECAPTCHA_PRIVATE_KEY': 'Recaptcha Секретный ключ',
    'ALLOW_FILE_ATTACHMENTS': 'Разрешить файловые вложения',
    'MAX_SIZE_MB': 'Максимальный размер (КБ)',
    'UPDATE_SETTINGS': 'Обновить настройки',
    'DEFAULT_LANGUAGE': 'Язык по умолчанию',
    'SUPPORTED_LANGUAGES': 'Поддерживаемые Языки',
    'SUPPORTED_LANGUAGES_INFO': 'Поддерживаемые языки являются языками, что билеты могут быть написаны в.',
    'ALLOWED_LANGUAGES': 'Разрешенные Языки',
    'ALLOWED_LANGUAGES_INFO': 'Разрешенные языки являются языками, которые могут быть использованы пользователем.',
    'SETTINGS_UPDATED': 'Настройки были обновлены',
    'ON': 'Я зажег',
    'OFF': 'выключено',
    'BOXED': 'в штучной упаковке',
    'FULL_WIDTH': 'Полная ширина',
    'LOAD_MORE': 'Загрузи больше',
    'MY_NOTIFICATIONS': 'Мои уведомления',
    'ALL_NOTIFICATIONS': 'Все уведомления',
    'VERIFY_SUCCESS': 'Пользователь проверен',
    'VERIFY_FAILED': 'Не удалось проверить',
    'ENABLE_USER_SYSTEM': 'Использовать пользовательскую систему для клиентов',
    'ENABLE_USER_REGISTRATION': 'Включить регистрацию пользователя',
    'INCLUDE_USERS_VIA_CSV': 'Включить пользователей через CSV-файл',
    'BACKUP_DATABASE': 'Резервная база данных',
    'DELETE_ALL_USERS': 'Удалить всех пользователей',
    'PLEASE_CONFIRM_PASSWORD': 'Подтвердите свой пароль, чтобы внести эти изменения.',
    'REGISTRATION_API_KEYS': 'Ключи регистрации API',
    'NAME_OF_KEY': 'Название ключа',
    'KEY': 'Ключ',
    'ADD_API_KEY': 'Добавить ключ API',
    'NO_KEY_SELECTED': 'Не выбран ключ',
    'CHECK_TICKET': 'Проверить билет',
    'ACTIVITY': 'Мероприятия',
    'HOME': 'Главная',
    'TICKET_NUMBER': 'Номер билета',
    'NEXT': 'следующий',
    'SUBJECT': 'Предмет',
    'SEND_EMAIL_ON_NEW_TICKET': 'Отправить письмо на новый билет',
    'STAFF_UPDATED': 'Сотрудник обновлен',
    'UPDATE': 'Обновить',
    'NEVER': 'Никогда',
    'HIMSELF': 'сам',
    'ADD_USER': 'Добавить пользователя',
    'UPLOAD_FILE': 'Загрузить файл',
    'PRIVATE': 'частный',
    'ENABLE_USER': 'Включить пользователя',
    'DISABLE_USER': 'Отключить пользователя',
    'SHOW_CLOSED_TICKETS': 'Показать закрытые билеты',
    'IMAGE_HEADER_URL': 'URL заголовка изображения',

    'CHART_CREATE_TICKET': 'Билеты создано',
    'CHART_CLOSE': ' Билеты закрыты',
    'CHART_SIGNUP': 'Количество пользователей, зарегистрированных',
    'CHART_COMMENT': 'Ответов',
    'CHART_ASSIGN': 'назначенный',

    //ACTIVITIES
    'ACTIVITY_COMMENT': 'прокомментировал билет на',
    'ACTIVITY_ASSIGN': 'назначен билет на',
    'ACTIVITY_UN_ASSIGN': ' ',
    'ACTIVITY_CLOSE': 'замкнутый билет',
    'ACTIVITY_CREATE_TICKET': 'создал билет',
    'ACTIVITY_RE_OPEN': 'возобновлено билет на',
    'ACTIVITY_DEPARTMENT_CHANGED': 'Измененный отдел билета',
    'ACTIVITY_PRIORITY_CHANGED': 'Измененный приоритет билета',

    'ACTIVITY_EDIT_SETTINGS': 'отредактированные настройки',
    'ACTIVITY_SIGNUP': 'подписали',
    'ACTIVITY_ADD_TOPIC': 'добавлена ​​тема',
    'ACTIVITY_ADD_ARTICLE': 'добавлена ​​статья',
    'ACTIVITY_DELETE_TOPIC': 'удалена тема',
    'ACTIVITY_DELETE_ARTICLE': 'удалена статья',
    'ACTIVITY_EDIT_ARTICLE': 'отредактирован статья',
    'ACTIVITY_ADD_STAFF': 'добавил персонал',
    'ACTIVITY_ADD_DEPARTMENT': 'добавлен отдел',
    'ACTIVITY_DELETE_DEPARTMENT': 'удален отдел',
    'ACTIVITY_EDIT_DEPARTMENT': 'отредактирован отдел',
    'ACTIVITY_ADD_CUSTOM_RESPONSE': 'добавленный пользовательский ответ',
    'ACTIVITY_DELETE_CUSTOM_RESPONSE': 'удален пользовательский ответ<',
    'ACTIVITY_EDIT_CUSTOM_RESPONSE': 'редактировать пользовательский ответ',
    'ACTIVITY_BAN_USER': 'исключенному пользователю',
    'ACTIVITY_DELETE_USER': 'удаленный пользователь',
    'ACTIVITY_UN_BAN_USER': 'исключенному пользователю',

    'SERVER_REQUIREMENTS': 'Требования к серверу',
    'DATABASE_CONFIGURATION': 'Конфигурация базы данных',
    'ADMIN_SETUP': 'Настройка администратора',
    'COMPLETED': 'Завершено',
    'INSTALL_HEADER_TITLE': 'Мастер установки OpenSupports',
    'INSTALL_HEADER_DESCRIPTION': 'Этот мастер поможет вам настроить и установить OpenSupports на ваш сайт',
    'SELECT_LANGUAGE': 'Выберите язык',
    'REQUIREMENT': 'Требование',
    'VALUE': 'Стоимость',
    'REFRESH': 'Обновить',
    'USER_SYSTEM': 'Пользовательская система',
    'PREVIOUS': 'Предыдущая',
    'DATABASE_HOST': 'Сервер MySQL',
    'DATABASE_PORT': 'Порт сервера MySQL',
    'DATABASE_NAME': 'Имя базы данных MySQL',
    'DATABASE_USER': 'Пользователь MySQL',
    'DATABASE_PASSWORD': 'Пароль MySQL',
    'ADMIN_NAME': 'Имя учетной записи администратора ',
    'ADMIN_EMAIL': 'Адрес электронной почты администратора',
    'ADMIN_PASSWORD': 'Пароль учетной записи администратора',
    'ADMIN_PASSWORD_DESCRIPTION': 'Пожалуйста, запомните этот пароль. Это необходимо для доступа к панели администратора. Вы можете изменить его позже.',
    'INSTALLATION_COMPLETED': 'Установка завершена.',
    'INSTALLATION_COMPLETED_DESCRIPTION': 'Установка OpenSupports завершена. Перенаправление на панель администратора ...',

    'STEP_TITLE': 'Шаг  {current} из {total} - {title}',
    'STEP_1_DESCRIPTION': 'Выберите нужный язык для мастера установки.',
    'STEP_2_DESCRIPTION': 'Здесь перечислены требования для запуска OpenSupports. Пожалуйста, убедитесь, что все требования выполнены.',
    'STEP_3_DESCRIPTION': 'Пожалуйста, заполните конфигурацию базы данных MySQL.',
    'STEP_4_DESCRIPTION': 'Выберите свои пользовательские настройки.',
    'STEP_5_DESCRIPTION': 'Пожалуйста, настройте учетную запись администратора.',
    'STEP_6_DESCRIPTION': 'Установка завершена.',
    'STEP_7_DESCRIPTION': 'Установка завершена.',

    //VIEW DESCRIPTIONS
    'CREATE_TICKET_DESCRIPTION': 'Это форма для создания билетов. Заполните форму и отправьте нам свои вопросы / сомнения / предложения. Наша система поддержки будет ответить на него как можно скорее.',
    'TICKET_LIST_DESCRIPTION': 'Здесь вы можете найти список всех билетов отправленных в нашу службу поддержки.',
    'TICKETS_DESCRIPTION': 'Отправить билет через наш центр поддержки и получить ответ ваши сомнения, предложения и вопросы.',
    'ARTICLES_DESCRIPTION': 'Посмотрите наши статьи о распространенных проблемах, руководств и документации',
    'ACCOUNT_DESCRIPTION': 'Все ваши билеты хранятся в вашем аккаунте профиль. Следите за всеми вашими билетами вы посылаете в нашу команду сотрудников.',
    'SUPPORT_CENTER_DESCRIPTION': 'Добро пожаловать в наш центр поддержки. Вы можете связаться с нами через систему билетов. Ваши билеты будут отвечать нашим персоналом.',
    'CUSTOM_RESPONSES_DESCRIPTION': 'Пользовательские ответы автоматизированные ответы для общих проблем',
    'MY_TICKETS_DESCRIPTION': 'Здесь вы можете посмотреть билеты вы несете ответственность за.',
    'NEW_TICKETS_DESCRIPTION': 'Здесь Вы можете просмотреть все новые билеты, которые не назначены кем-либо.',
    'ALL_TICKETS_DESCRIPTION': 'Здесь вы можете посмотреть билеты департаментов вы назначены.',
    'TICKET_VIEW_DESCRIPTION': 'Этот билет был отправлен клиентом. Здесь вы можете ответить или назначить билет',
    'BAN_USERS_DESCRIPTION': 'Здесь вы можете увидеть список запрещенных писем, вы можете снять запрет их или добавить больше электронных писем в список.',
    'LIST_USERS_DESCRIPTION': 'Это список пользователей, зарегистрированных в этой платформе. Вы можете найти кого-то, в частности, удалить его или запретить его.',
    'USER_VIEW_DESCRIPTION': 'Здесь вы можете найти всю информацию об пользователя, и все билеты, отправленных пользователем. Можно также удалить или запретить его.',
    'DELETE_USER_DESCRIPTION': 'Пользователь не сможет войти в систему старения и все билеты будут стерты. Кроме того, электронная почта не может больше использоваться.',
    'DELETE_TOPIC_DESCRIPTION': 'При удалении темы, будут удалены все статьи о нем.',
    'EDIT_TOPIC_DESCRIPTION': 'Здесь вы можете изменить название, значок и значок цвета темы.',
    'ADD_ARTICLE_DESCRIPTION': 'Здесь вы можете добавить статью, которая будет доступна для каждого пользователя. Он будет добавлен в категорию {category}.',
    'LIST_ARTICLES_DESCRIPTION': 'Это список статей, которые включает в себя информацию о наших услугах.',
    'ADD_TOPIC_DESCRIPTION': 'Здесь вы можете добавить тему, которая работает как категория для статей.',
    'DELETE_ARTICLE_DESCRIPTION': 'Вы собираетесь удалить эту статью навсегда.',
    'STAFF_MEMBERS_DESCRIPTION': 'Здесь вы можете увидеть, кто ваши сотрудники.',
    'ADD_STAFF_DESCRIPTION': 'Здесь вы можете добавить сотрудников вашей команды.',
    'EDIT_STAFF_DESCRIPTION': 'Здесь вы можете редактировать информацию о сотрудника',
    'MY_ACCOUNT_DESCRIPTION': 'Здесь вы можете редактировать информацию о вас.',
    'DEPARTMENTS_DESCRIPTION': 'Отдел представляет собой группу, где билеты можно идти. Они используются для классификации билетов. Вы можете назначить их другим сотрудникам.',
    'MAINTENANCE_MODE_DESCRIPTION': 'Система поддержки находится в режиме обслуживания, таким образом, в данный момент недоступен. Мы вернемся, как можно скорее.',
    'EMAIL_TEMPLATES_DESCRIPTION': 'Здесь вы можете редактировать шаблоны писем, которые будут отправлены пользователям. Следует помнить, что двойные скобки, фигурные скобки указывают на значение переменной. Например, \' имя\' представляет имя пользователя.',
    'SYSTEM_PREFERENCES_DESCRIPTION': 'Здесь вы можете изменить настройки системы.',
    'VERIFY_SUCCESS_DESCRIPTION': 'You пользователь был проверен правильно. Теперь вы можете войти.',
    'VERIFY_FAILED_DESCRIPTION': 'Проверка не может быть сделано.',
    'STATISTICS_DESCRIPTION': 'Здесь вы можете просмотреть статистику, связанные с билетами и подписаться на рассылку.',
    'ADVANCED_SETTINGS_DESCRIPTION': 'Здесь вы можете изменить дополнительные настройки вашей системы. Пожалуйста, будьте осторожны, изменения, которые вы делаете, не могут быть отменены.',
    'USER_SYSTEM_DISABLED': 'Пользовательская система отключена',
    'USER_SYSTEM_ENABLED': 'Пользовательская система включена',
    'REGISTRATION_DISABLED': 'Регистрация отключена',
    'REGISTRATION_ENABLED': 'Регистрация включена',
    'ADD_API_KEY_DESCRIPTION': 'Вставьте имя и регистрационный ключ.',
    'SIGN_UP_VIEW_DESCRIPTION': 'Здесь вы можете создать учетную запись для нашего центра поддержки. Он необходим для отправки билетов и просмотра документации.',
    'EDIT_PROFILE_VIEW_DESCRIPTION': 'Здесь вы можете изменить своего пользователя, изменив свой адрес электронной почты или пароль.',
    'ENABLE_USER_SYSTEM_DESCRIPTION': 'Включить / отключить использование пользовательской системы. Если вы отключите его, все пользователи будут удалены. Если вы включите его, будут созданы пользователи существующих билетов.',
    'CSV_DESCRIPTION': 'Файл CSV должен иметь 3 столбца: адрес электронной почты, пароль и имя. Количество строк не ограничено. В файле будет создан один пользователь для каждой строки.',
    'SMTP_SERVER_DESCRIPTION': 'Конфигурация SMTP-сервера позволяет приложению отправлять письма. Если вы не настроите его, никакие электронные письма не будут отправлены OpenSupports.',
    'ENABLE_USER_DESCRIPTION': 'Это действие позволяет пользователю входить в систему и создавать билеты.',
    'DISABLE_USER_DESCRIPTION': 'Пользователь будет отключен и не сможет войти и создать билеты',
    'PRIVATE_RESPONSE_DESCRIPTION': 'Этот ответ будет рассматриваться только сотрудниками',
    'PRIVATE_TOPIC_DESCRIPTION': 'Эта тема будет видна только сотрудникам',
    'PRIVATE_DEPARTMENT_DESCRIPTION': 'Этот отдел будет рассматриваться только сотрудниками',
    'PRIVATE_DESCRIPTION': 'Этот ответ будет рассматриваться только сотрудниками',

    //ERRORS
    'EMAIL_OR_PASSWORD': 'электронной почты или пароль недействительный',
    'EMAIL_NOT_EXIST': 'электронной почты не существует',
    'ERROR_EMPTY': 'Неверное значение',
    'ERROR_PASSWORD': 'Неверный пароль',
    'ERROR_NAME': 'Неверное имя',
    'ERROR_TITLE': 'Недопустимый заголовок',
    'ERROR_EMAIL': 'Неверный адрес электронной почты',
    'ERROR_CONTENT_SHORT': 'Содержание слишком короткий',
    'PASSWORD_NOT_MATCH': 'Пароль не подходит',
    'INVALID_RECOVER': 'Восстановление неверных данных',
    'TICKET_SENT_ERROR': 'Произошла ошибка при попытке создать билет.',
    'TICKET_COMMENT_ERROR': 'Произошла ошибка при попытке добавить свой комментарий.',
    'NO_PERMISSION': 'У вас нет разрешения на доступ к этой странице.',
    'INVALID_USER': 'Идентификатор пользователя недействителен',
    'ERROR_RETRIEVING_TICKETS': 'Произошла ошибка при попытке получить билеты.',
    'ERROR_RETRIEVING_USERS': 'Произошла ошибка при попытке получить пользователей.',
    'ERROR_RETRIEVING_BAN_LIST': 'Произошла ошибка при попытке получить список запрещенных писем.',
    'ERROR_BANNING_EMAIL': 'Произошла ошибка при попытке запретить электронную почту.',
    'ERROR_RETRIEVING_ARTICLES': 'Произошла ошибка при попытке получить статьи.',
    'ERROR_LIST': 'Выберите по крайней мере один',
    'ERROR_URL': 'Неверная ссылка',
    'UNVERIFIED_EMAIL': 'электронный адрес еще не подтвержден',
    'ERROR_UPDATING_SETTINGS': 'Произошла ошибка при попытке обновления параметров',
    'INVALID_EMAIL_OR_TICKET_NUMBER': 'Неправильный номер эл. Почты или номера билета.',
    'INVALID_FILE': 'неверный файл',
    'ERRORS_FOUND': 'Ошибки найдены',
    'ERROR_IMAGE_SIZE': 'Изображение не может иметь размер больше {size} МБ',
    'USER_DISABLED': 'Эта учетная запись отключена.',
    'DEPARTMENT_PRIVATE_TICKETS': 'У этого отдела есть билеты, созданные не сотрудниками, и он не может быть приватным',
    'INVALID_SYNTAX': 'Недопустимый синтаксис.',

    //MESSAGES
    'SIGNUP_SUCCESS': 'Вы успешно зарегистрировались в нашей системе поддержки.',
    'TICKET_SENT': 'Билет был успешно создан.',
    'VALID_RECOVER': 'Пароль успешно восстановлены',
    'EMAIL_EXISTS': 'адрес электронной почты уже существует',
    'ARE_YOU_SURE': 'Ты уверен?',
    'EMAIL_WILL_CHANGE': 'Текущий адрес электронной почты будет изменен',
    'PASSWORD_WILL_CHANGE': 'Текущий пароль будет изменен',
    'EMAIL_CHANGED': 'адрес электронной почты был успешно изменен',
    'PASSWORD_CHANGED': 'Пароль был успешно изменен',
    'OLD_PASSWORD_INCORRECT': 'Старый неверный пароль',
    'WILL_LOSE_CHANGES': 'Вы не спасают. Ваши изменения будут потеряны.',
    'WILL_DELETE_CUSTOM_RESPONSE': 'Обычай ответ будет удален.',
    'WILL_DELETE_DEPARTMENT': 'Отдел будет удален. Все билеты будут передача в отдел выбранного.',
    'NO_STAFF_ASSIGNED': 'Ни один сотрудник не будет назначен в этот отдел.',
    'NO_DEPARTMENT_ASSIGNED': 'Вам не назначено билетное ведомство.',
    'LEVEL_UPDATED': 'Уровень был успешно обновлен.',
    'DEPARTMENTS_UPDATED': 'Отделы были успешно обновлены.',
    'FAILED_EDIT_STAFF': 'Произошла ошибка при попытке редактирования сотрудника.',
    'EMAIL_BANNED_SUCCESSFULLY': 'Электронная почта была успешно запрещена',
    'WILL_DELETE_STAFF': 'Этот сотрудник будет удален, а все его билеты будут Unassigned.',
    'WILL_RECOVER_EMAIL_TEMPLATE': 'Этот шаблон электронной почты будет восстанавливаться, чтобы это значение по умолчанию на этом языке.',
    'SUCCESS_IMPORTING_CSV_DESCRIPTION': 'Файл CSV успешно установлен',
    'SUCCESS_DELETING_ALL_USERS': 'Пользователи удалили beend',

    'LAST_7_DAYS': 'Последние 7 дней',
    'LAST_30_DAYS': 'Последние 30 дней',
    'LAST_90_DAYS': 'Последние 90 дней',
    'LAST_365_DAYS': 'Последние 365 дней',

    'ACTIVITY_COMMENT_THIS': 'прокомментировал этот билет',
    'ACTIVITY_ASSIGN_THIS': 'присвоен этот билет в',
    'ACTIVITY_UN_ASSIGN_THIS': 'неназначенный билет в',
    'ACTIVITY_CLOSE_THIS': 'закрыл этот билет',
    'ACTIVITY_CREATE_TICKET_THIS': 'создал этот билет',
    'ACTIVITY_RE_OPEN_THIS': 'снова открыт этот билет',
    'ACTIVITY_DEPARTMENT_CHANGED_THIS': 'изменен отдел этого билета на',
    'ACTIVITY_PRIORITY_CHANGED_THIS': 'изменил приоритет этого билета на',
    'DATE_PREFIX': '',
    'LEFT_EMPTY_DATABASE': 'Пусто для автоматического создания базы данных',
    'REMEMBER_ME': 'Запомнить',
    'EMAIL_LOWERCASE': 'Эл. адрес',
    'PASSWORD_LOWERCASE': 'пароль',
    'DEFAULT_PORT': 'Оставьте пустым для 3306 по умолчанию',
    'TEST_SMTP_CONNECTION': 'Тестирование SMTP-соединения',
    'SMTP_CONNECTION_SUCCESS': 'Учетные данные SMTP действительны.',
    'SMTP_CONNECTION_ERROR': 'Не удается подключиться к SMTP-серверу.'
};
