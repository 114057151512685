﻿export default {
     'WELCOME': 'Καλώς Ορίσατε',
     'TICKETS': 'Εισιτήρια',
     'ARTICLES': 'Άρθρα',
     'ACCOUNT': 'Λογαριασμός',
     'SUBMIT': 'Υποβολή',
     'EMAIL': 'Email',
     'PASSWORD': 'Κωδικός Πρόσβασης',
     'REPEAT_PASSWORD': 'Επανάληψη κωδικού πρόσβασης',
     'LOG_IN': 'Σύνδεση',
     'SIGN_UP': 'Εγγραφή',
     'FORGOT_PASSWORD': 'Ξέχασες τον κωδικό;',
     'RECOVER_PASSWORD': 'Ανακτήστε τον κωδικό σας',
     'RECOVER_SENT': 'Ένα μήνυμα ηλεκτρονικού ταχυδρομείου με οδηγίες ανάκτησης σας έχει σταλεί.',
     'NEW_EMAIL': 'Καινούργιο μήνυμα ηλεκτρονικού ταχυδρομείου',
     'FULL_NAME': 'Όνομα',
     'OLD_PASSWORD': 'Παλιός Κωδικός',
     'NEW_PASSWORD': 'Καινούργιος Κωδικός',
     'REPEAT_NEW_PASSWORD': 'Επαναλάβατε καινούργιο κωδικό',
     'BACK_LOGIN_FORM': 'Πίσω στην φόρμα σύνδεσης',
     'VIEW_ARTICLES': 'Δες Άρθρα',
     'EDIT_PROFILE': 'Αλλαγή Προφίλ',
     'CLOSE_SESSION': 'Κλείσιμο Συνεδρίας',
     'CREATE_TICKET': 'Δημιουργία Εισιτηρίου',
     'TICKET_LIST': 'Λίστα Εισιτηρίων',
     'SUPPORT_CENTER': 'Κέντρο Υποστήριξης',
     'DEPARTMENT': 'Τμήμα',
     'AUTHOR': 'Συγγραφέας',
     'DATE': 'Ημερομηνία',
     'RESPOND': 'Απάντηση',
     'RESPOND_TICKET': 'Απάντηση Εισιτηρίου',
     'CLOSE_TICKET': 'κλειστό εισιτήριο',
     'DELETE_TICKET': 'Διαγραφή εισιτηρίου',
     'NO_ATTACHMENT': 'Χωρίς Συνημμένα Αρχεία',
     'STAFF': 'Προσωπικό',
     'CUSTOMER': 'Πελάτης',
     'YES': 'Ναι',
     'NO': 'Όχι',
     'CANCEL': 'Ακύρωση',
     'MY_ACCOUNT': 'Ο Λογαριασμός Μου',
     'DASHBOARD': 'Πίνακας Ελέγχου',
     'USERS': 'Χρήστες',
     'SETTINGS': 'Ρυθμίσεις',
     'STATISTICS': 'Στατιστικά',
     'LAST_ACTIVITY': 'Τελευταία Σύνδεση',
     'MY_TICKETS': 'Τα δικά μου Εισιτήρια',
     'NEW_TICKETS': 'Καινούργια Εισιτήρια',
     'ALL_TICKETS': 'Όλα τα Εισιτήρια',
     'CUSTOM_RESPONSES': 'Απαντήσεις Πελατών',
     'LIST_USERS': 'Λίστα Χρηστών',
     'BAN_USERS': 'Αποκλεισμός Χρηστών',
     'LIST_ARTICLES': 'Λίστα Άρθρων',
     'STAFF_MEMBERS': 'Μέλη Προσωπικού',
     'DEPARTMENTS': 'Τμήματα',
     'SYSTEM_PREFERENCES': 'Ρυθμίσεις Συστήματος',
     'ADVANCED_SETTINGS': 'Προηγμένες Ρυθμίσεις',
     'EMAIL_TEMPLATES': 'Πρότυπα Ηλεκτρονικού Ταχυδρομείου',
     'FILTERS_CUSTOM_FIELDS': 'Φίλτρα και Προσαρμοσμένα Πεδία',
     'PRIORITY': 'Προτεραιότητα',
     'NUMBER': 'Αριθμός',
     'HIGH': 'Υψηλός',
     'MEDIUM': 'Μέτριος',
     'LOW': 'Χαμηλός',
     'TITLE': 'Τίτλος',
     'CONTENT': 'Περιεχόμενο',
     'SAVE': 'Αποθήκευση',
     'DISCARD_CHANGES': 'Ακύρωση Αλλαγών',
     'DELETE': 'Διαγραφή',
     'LANGUAGE': 'Γλώσσα',
     'OWNER': 'Ιδιοκτήτης',
     'OWNED': 'Ιδιοκτησία',
     'STATUS': 'Κατάσταση',
     'NONE': 'Κανένα',
     'OPENED': 'Εκκρεμεί',
     'CLOSED': 'Κλειστό',
     'CLOSE': 'Κλείσε',
     'RE_OPEN': 'Ξαναεκκρεμεί',
     'ASSIGN_TO_ME': 'Ανατέθηκε σε μένα',
     'UN_ASSIGN': 'Μη αναθετημένο',
     'VIEW_TICKET': 'Προβολή Εισιτηρίου',
     'VIEW_TICKET_DESCRIPTION': 'Ελέγξτε την κατάσταση του εισιτηρίου σας χρησιμοποιώντας τον αριθμό του εισιτηρίου και την διεύθυνση ηλεκτρονικού ταχυδρομείου σας.',
     'SELECT_CUSTOM_RESPONSE': 'Επιλογή μιας προσαρμοσμένης απάντησης...',
     'WARNING': 'Προειδοποίηση',
     'INFO': 'Πληροφορία',
     'ALL_DEPARTMENTS': 'Όλα τα τμήματα',
     'EMAIL_BANNED': 'Η διεύθυνση ηλεκτρονικού ταχυδρομείου έχει αποκλειστεί',
     'UN_BAN': 'Αναίρεση αποκλεισμού',
     'BAN_NEW_EMAIL': 'Αποκλεισμός καινούργιας διεύθυνσης ηλεκτρονικού ταχυδρομείου',
     'BAN_EMAIL': 'Αποκλεισμός διεύθυνσης ηλεκτρονικού ταχυδρομείου',
     'EDIT_EMAIL': 'Αλλαγή διεύθυνσης ηλεκτρονικού ταχυδρομείου',
     'EDIT_PASSWORD': 'Αλλαγή μυστικού κωδικού (κωδικός πρόσβασης)',
     'CHANGE_EMAIL': 'Αλλαγή διεύθυνσης ηλεκτρονικού ταχυδρομείου',
     'CHANGE_PASSWORD': 'Αλλαγή μυστικού κωδικού (κωδικός πρόσβασης)',
     'NAME': 'Όνομα',
     'SIGNUP_DATE': 'Ημερομηνία εγγραφής',
     'SEARCH_USERS': 'Αναζήτηση χρηστών...',
     'SEARCH_EMAIL': 'Αναζήτηση διεύθυνσης ηλεκτρονικού ταχυδρομείου...',
     'USER_VIEW_TITLE': 'Χρήστης #{userId}',
     'EDIT_TOPIC': 'Αλλαγή Θέματος',
     'ADD_TOPIC': 'Προσθήκη Θέματος',
     'ICON': 'Εικονίδιο',
     'COLOR': 'Χρώμα',
     'ADD_NEW_ARTICLE': 'Προσθήκη καινούργιου άρθρου',
     'ADD_ARTICLE': 'Προσθήκη άρθρου',
     'LAST_EDITED_IN': 'Τελευταία αλλαγή {date}',
     'EDIT': 'Αλλαγή',
     'NO_RESULTS': 'Χωρίς αποτελέσματα',
     'DELETE_AND_BAN': 'Διαγραφή ',
     'STAFF_LEVEL': 'Επίπεδο Προσωπικού',
     'ASSIGNED': 'Ανατεθειμένο',
     'ASSIGNED_TICKETS': '{tickets} ανατεθειμένα εισιτήρια',
     'CLOSED_TICKETS': '{tickets} κλειστά εισιτήρια',
     'LAST_LOGIN': 'Τελευταία Είσοδος',
     'ADD_NEW_STAFF': 'Πρόσθεσε νέο προσωπικό',
     'ADD_STAFF': 'Πρόσθεσε Προσωπικό',
     'LEVEL': 'Επίπεδο',
     'LEVEL_1': 'Επίπεδο 1 (Tickets)',
     'LEVEL_2': 'Επίπεδο 2 (Tickets + Articles)',
     'LEVEL_3': 'Επίπεδο 3 (Tickets + Articles+ Staff)',
     'LEVEL_1_DESCRIPTION': 'μπορεί μόνο να ανταποκριθεί σε εισιτήρια και να διαχειριστεί χρήστες.',
     'LEVEL_2_DESCRIPTION': 'μπορεί να κάνει οτιδήποτε κάνει το Επίπεδο 1, μπορεί να δημιουργήσει ή να επεξεργαστεί άρθρα και μπορεί να δημιουργήσει προσαρμοσμένες απαντήσεις.',
     'LEVEL_3_DESCRIPTION': 'μπορεί να κάνει οτιδήποτε κάνει το Επίπεδο 2, μπορεί να δημιουργήσει ή να επεξεργαστεί τα μέλη του προσωπικού και να διαχειριστεί ολόκληρο το σύστημα.',
     'UPDATE_EMAIL': 'Ενημέρωση ηλεκτρονικής διεύθυνσης',
     'UPDATE_PASSWORD': 'Ενημέρωση κωδικών πρόσβασης',
     'UPDATE_LEVEL': 'Ενημέρωση Επιπέδου',
     'UPDATE_DEPARTMENTS': 'Ενημέρωση Τμημάτων',
     'EDIT_STAFF': 'Επεξεργασία Μελών Προσωπικών',
     'ADD_DEPARTMENT': 'Προσθήκη Τμήματος',
     'UPDATE_DEPARTMENT': 'Ενημέρωση Τμήματος',
     'TRANSFER_TICKETS_TO': 'Μεταφορά εισιτηρίων σε',
     'COMMENTS': 'Σχόλια',
     'DELETE_STAFF_MEMBER': 'Διαγραφή Μέλους του Προσωπικού',
     'MAINTENANCE_MODE': 'Λειτουργία Συντήρησης',
     'MAINTENANCE_MODE_INFO': 'Θα απενεργοποιήσει προσωρινά το σύστημα για τακτικούς χρήστες.',
     'RECOVER_DEFAULT': 'Ανάκτηση Προεπιλογών',
     'SUPPORT_CENTER_URL': 'Υποστήριξη Κεντρικού URL',
     'SUPPORT_CENTER_TITLE': 'Υποστήριξη Κεντρικού Τίτλου',
     'SUPPORT_CENTER_LAYOUT': 'Υποστήριξη Κεντρικού Διάταξης',
     'DEFAULT_TIMEZONE': 'Προκαθορισμένη Ζώνη Ώρας (GMT)',
     'NOREPLY_EMAIL': 'Noreply Μήνυμα Ηλεκτρονικού Ταχυδρομείου',
     'SMTP_USER': 'SMTP Χρήστης',
     'SMTP_SERVER': 'SMTP Εξυπηρετητής',
     'SMTP_PASSWORD': 'SMTP Κωδικός Πρόσβασης',
     'PORT': 'Θύρα',
     'RECAPTCHA_PUBLIC_KEY': 'Recaptcha Δημοσίου Κλειδιού',
     'RECAPTCHA_PRIVATE_KEY': 'Recaptcha Ιδιωτικού Κλειδιού',
     'ALLOW_FILE_ATTACHMENTS': 'Επιτρέπονται Συνημμένα Αρχεία',
     'MAX_SIZE_MB': 'Μέγιστο Μέγεθος (MB)',
     'UPDATE_SETTINGS': 'Ενημέρωση Ρυθμίσεων',
     'DEFAULT_LANGUAGE': 'Προεπιλεγμένες Γλώσσες',
     'SUPPORTED_LANGUAGES': 'Υποστηριζόμενες Γλώσσες',
     'SUPPORTED_LANGUAGES_INFO': 'Οι υποστηριζόμενες γλώσσες είναι οι γλώσσες στις οποίες μπορούν να γραφτούν τα εισιτήρια.',
     'ALLOWED_LANGUAGES': 'Επιτρεπόμενες Γλώσσες',
     'ALLOWED_LANGUAGES_INFO': 'Οι επιτρεπόμενες γλώσσες είναι οι γλώσσες οι οποίες μπορούν να χρησιμοποιηθούν από έναν χρήστη.',
     'SETTINGS_UPDATED': 'Οι ρυθμίσεις έχουν ενημερωθεί',
     'ON': 'Ενεργό',
     'OFF': 'Ανενεργό',
     'BOXED': 'Θέτω εις Κιβώτιο',
     'FULL_WIDTH': 'Πλήρες πλάτος',
     'LOAD_MORE': 'Φόρτωσε Περισσότερα',
     'MY_NOTIFICATIONS': 'Οι ειδοποιήσεις μου',
     'ALL_NOTIFICATIONS': 'Όλες οι ειδοποιήσεις',
     'VERIFY_SUCCESS': 'Επιτυχής Επιβεβαίωση Χρήστη',
     'VERIFY_FAILED': 'Δεν ήταν δυνατή η επαλήθευση',
     'ENABLE_USER_SYSTEM': 'Χρησιμοποιήστε το σύστημα χρήστη για πελάτες',
     'ENABLE_USER_REGISTRATION': 'Ενεργοποίηση εγγραφής χρήστη',
     'INCLUDE_USERS_VIA_CSV': 'Συμπερίληψη χρηστών μέσω CSV αρχείου',
     'BACKUP_DATABASE': 'Δημιουργία αντιγράφων ασφαλείας της βάσης δεδομένων',
     'DELETE_ALL_USERS': 'Διαγραφή όλων των χρηστών',
     'PLEASE_CONFIRM_PASSWORD': 'Παρακαλώ επιβεβαιώστε τον κωδικό πρόσβασης σας για να κάνετε αυτές τις αλλαγές',
     'REGISTRATION_API_KEYS': 'Καταχώριση API κλειδιών',
     'NAME_OF_KEY': 'Όνομα κλειδιού',
     'KEY': 'Κλειδί',
     'ADD_API_KEY': 'Προσθήκη API κλειδιού',
     'NO_KEY_SELECTED': 'Δεν έχει επιλεχθεί κλειδί',
     'CHECK_TICKET': 'Έλεγχος Εισιτηρίου',
     'ACTIVITY': 'Δραστηριότητα',
     'HOME': 'Σπίτι',
     'TICKET_NUMBER': 'Αριθμός εισιτηρίου',
     'NEXT': 'Επόμενο',
    'SUBJECT': 'Θέμα',
     'SEND_EMAIL_ON_NEW_TICKET': 'Στείλτε μήνυμα ηλκετορνικού ταχυδρομείου στο νέο εισιτήριο',
     'STAFF_UPDATED': 'Το μέλος προσωπικού έχει ενημερωθεί',
     'UPDATE': 'Ενημέρωση',
     'NEVER': 'Ποτέ',
     'HIMSELF': 'ο ίδιος',
     'ADD_USER': 'Πρόσθεσε χρήστη',
     'UPLOAD_FILE': 'Ανέβασμα αρχείου',
     'PRIVATE': 'ιδιωτικός',
     'ENABLE_USER': 'Ενεργοποίηση χρήστη',
     'DISABLE_USER': 'Απενεργοποίηση χρήστη',
     'SHOW_CLOSED_TICKETS': 'Εμφάνιση κλειστών εισιτηρίων',
     'IMAGE_HEADER_URL': 'Διεύθυνση URL κεφαλίδας εικόνας',

     'CHART_CREATE_TICKET': 'Τα εισιτήρια δημιουργήθηκαν',
     'CHART_CLOSE': 'Τα εισιτήρια κλείσανε',
     'CHART_SIGNUP': 'Εγγραφές',
     'CHART_COMMENT': 'Απαντήσεις',
     'CHART_ASSIGN': 'Εκχώρηση',

     //ACTIVITIES
     'ACTIVITY_COMMENT': 'σχολίασε εισιτήριο',
     'ACTIVITY_ASSIGN': 'εκχωρημένο εισιτήριο',
     'ACTIVITY_UN_ASSIGN': 'μη εκχωρημένο εισιτήριο',
     'ACTIVITY_CLOSE': 'κλειστό εισιτήριο',
     'ACTIVITY_CREATE_TICKET': 'δημιουργημένο εισιτήριο',
     'ACTIVITY_RE_OPEN': 'ανοιγμένο ξανά εισιτήριο',
     'ACTIVITY_DEPARTMENT_CHANGED': 'αλλαγή τμήματος εισιτηρίου',
     'ACTIVITY_PRIORITY_CHANGED': 'αλλαγή προτεραιότητας εισιτηρίου',

     'ACTIVITY_EDIT_SETTINGS': 'τροποποιημένες ρυθμίσεις',
     'ACTIVITY_SIGNUP': 'υπέγραψε',
     'ACTIVITY_ADD_TOPIC': 'προστεθειμένο θέμα',
     'ACTIVITY_ADD_ARTICLE': 'προστεθειμένο άρθρο',
     'ACTIVITY_DELETE_TOPIC': 'διαγραμμένο θέμα',
     'ACTIVITY_DELETE_ARTICLE': 'διαγραμμένο άρθρο',
     'ACTIVITY_EDIT_ARTICLE': 'τροποποιημένο άρθρο',
     'ACTIVITY_ADD_STAFF': 'προστεθειμένο προσωπικό',
     'ACTIVITY_ADD_DEPARTMENT': 'προστεθειμένο τμήμα',
     'ACTIVITY_DELETE_DEPARTMENT': 'deleted τμήμα',
     'ACTIVITY_EDIT_DEPARTMENT': 'επεξεργασμένο τμήμα',
     'ACTIVITY_ADD_CUSTOM_RESPONSE': 'προστεθειμένη προσαρμοσμένη απάντηση',
     'ACTIVITY_DELETE_CUSTOM_RESPONSE': 'διαγράφηκε προσαρμοσμένη απάντηση',
     'ACTIVITY_EDIT_CUSTOM_RESPONSE': 'επεξεργασμένη προσαρμοσμένη απάντηση',
     'ACTIVITY_BAN_USER': 'απαγορευμένος χρήστης',
     'ACTIVITY_DELETE_USER': 'διαγραμμένος χρήστη',
     'ACTIVITY_UN_BAN_USER': 'μη απαγορευμένος χρήστης',

     'SERVER_REQUIREMENTS': 'Απαιτήσεις εξυπηρετητή',
     'DATABASE_CONFIGURATION': 'Διαμόρφωση Βάσης Δεδομένων',
     'ADMIN_SETUP': 'Διαμόρφωση διαχειριστή',
     'COMPLETED': 'Ολοκληρώθηκε',
     'INSTALL_HEADER_TITLE': 'OpenSupports Οδηγός εγκατάστασης',
     'INSTALL_HEADER_DESCRIPTION': 'Αυτός ο οδηγός θα σας βοηθήσει να διαμορφώσετε και να εγκαταστήσετε το OpenSupport στον ιστότοπό σας',
     'SELECT_LANGUAGE': 'Επιλογή γλώσσας',
     'REQUIREMENT': 'Απαίτηση',
     'VALUE': 'Τιμή',
     'REFRESH': 'Ανανέωση',
     'USER_SYSTEM': 'Σύστημα χρήστη',
     'PREVIOUS': 'Προηγούμενο',
     'DATABASE_HOST': 'MySQL εξυπηρετητής',
     'DATABASE_PORT': 'MySQL Θύρα διακομιστή',
     'DATABASE_NAME': 'Όνομα MySQL βάσης δεδομένων',
     'DATABASE_USER': 'MySQL χρήστης',
     'DATABASE_PASSWORD': 'MySQL κωδικός πρόσβασης',
     'ADMIN_NAME': 'Όνομα λογαριασμού Διαχειριστή',
     'ADMIN_EMAIL': 'Λογαριασμός ηλεκτρονικής διεύθυνσης διαχειριστή',
     'ADMIN_PASSWORD': 'Κωδικός πρόσβασης λογαριασμού διαχειριστή',
     'ADMIN_PASSWORD_DESCRIPTION': 'Παρακαλώ να θυμάστε αυτόν τον κωδικό πρόσβασης. Απαιτείται για πρόσβαση στον πίνακα διαχείρισης. Μπορείτε να τον αλλάξετε αργότερα.',
     'INSTALLATION_COMPLETED': 'Η εγκατάσταση ολοκληρώθηκε.',
     'INSTALLATION_COMPLETED_DESCRIPTION': 'Η εγκατάσταση του OpenSupports ολοκληρώθηκε. Ανακατεύθυνση σε πίνακα διαχείρισης...',

     'STEP_TITLE': 'Βήμα {current} από {total} - {title}',
     'STEP_1_DESCRIPTION': 'Επιλέξτε τη γλώσσα που προτιμάτε για τον οδηγό εγκατάστασης.',
     'STEP_2_DESCRIPTION': 'Παρακάτω αναφέρονται οι απαιτήσεις για την εκτέλεση του OpenSupport. Βεβαιωθείτε ότι πληρούνται όλες οι απαιτήσεις.',
     'STEP_3_DESCRIPTION': 'Συμπληρώστε τη διαμόρφωση της βάσης δεδομένων MySQL.',
     'STEP_4_DESCRIPTION': 'Παρακαλώ επιλέξτε τις προτιμήσεις του συστήματος χρήστη.',
     'STEP_5_DESCRIPTION': 'Παρακαλώ συμπληρώστε τις γενικές προτιμήσεις συστήματος.',
     'STEP_6_DESCRIPTION': 'Παρακαλώ ρυθμίστε τον λογαριασμό διαχειριστή.',
     'STEP_7_DESCRIPTION': 'Η εγκατάσταση ολοκληρώθηκε.',

     //VIEW DESCRIPTIONS
     'CREATE_TICKET_DESCRIPTION': 'Αυτή είναι μια φόρμα για τη δημιουργία εισιτηρίων. Συμπληρώστε τη φόρμα και στείλτε μας τα θέματα / αμφιβολίες / προτάσεις σας. Το σύστημα υποστήριξης μας θα απαντήσει όσο το δυνατόν συντομότερα.',
     'TICKET_LIST_DESCRIPTION': 'Εδώ μπορείτε να βρείτε μια λίστα με όλα τα εισιτήρια που έχετε στείλει στην ομάδα υποστήριξης.',
     'TICKETS_DESCRIPTION': 'Στείλτε εισιτήριο μέσω του κέντρου υποστήριξης και λάβετε απάντηση από τις αμφιβολίες, τις προτάσεις και τα ζητήματά σας.',
     'ARTICLES_DESCRIPTION': 'Ρίξτε μια ματιά στα άρθρα μας σχετικά με κοινά θέματα, οδηγούς και τεκμηρίωση.',
     'ACCOUNT_DESCRIPTION': 'Όλα τα εισιτήρια σας αποθηκεύονται στο προφίλ του λογαριασμού σας. Παρακολουθήστε όλα τα εισιτήρια που στέλνετε στην ομάδα του προσωπικού μας.',
     'SUPPORT_CENTER_DESCRIPTION': 'Καλώς ήλθατε στο κέντρο υποστήριξης μας. Μπορείτε να επικοινωνήσετε μαζί μας μέσω του συστήματος εισιτηρίων. Τα εισιτήρια σας θα απαντηθούν από το προσωπικό μας.',
     'CUSTOM_RESPONSES_DESCRIPTION': 'Οι προσαρμοσμένες απαντήσεις είναι αυτοματοποιημένες απαντήσεις για κοινά προβλήματα',
     'MY_TICKETS_DESCRIPTION': 'Εδώ μπορείτε να δείτε τα εισιτήρια για τα οποία είστε υπεύθυνοι.',
     'NEW_TICKETS_DESCRIPTION': 'Εδώ μπορείτε να δείτε όλα τα νέα εισιτήρια που δεν έχουν ανατεθεί από κανέναν.',
     'ALL_TICKETS_DESCRIPTION': 'Εδώ μπορείτε να δείτε τα εισιτήρια των τμημάτων που έχετε εκχωρήσει.',
     'TICKET_VIEW_DESCRIPTION': 'Αυτό το εισιτήριο έχει αποσταλεί από έναν πελάτη. Εδώ μπορείτε να απαντήσετε ή να εκχωρήσετε το εισιτήριο',
     'BAN_USERS_DESCRIPTION': 'Εδώ μπορείτε να δείτε μια λίστα με απαγορευμένα μηνύματα ηλεκτρονικού ταχυδρομείου, μπορείτε να τα απενεργοποιήσετε ή να προσθέσετε περισσότερα μηνύματα ηλεκτρονικού ταχυδρομείου στη λίστα.',
     'LIST_USERS_DESCRIPTION': 'Αυτή είναι η λίστα των χρηστών που είναι εγγεγραμμένοι σε αυτήν την πλατφόρμα. Μπορείτε να αναζητήσετε κάποιον συγκεκριμένα, να τον διαγράψετε ή να τον απαγορεύσετε.',
     'USER_VIEW_DESCRIPTION': 'Εδώ μπορείτε να βρείτε όλες τις πληροφορίες σχετικά με ένα χρήστη και όλα τα εισιτήρια που στέλνει ο χρήστης. Μπορείτε επίσης να τα διαγράψετε ή να τα απαγορεύσετε.',
     'DELETE_USER_DESCRIPTION': 'Ο χρήστης δεν θα μπορέσει να συνδεθεί με τη γήρανση και όλα τα εισιτήρια του θα διαγραφούν. Επίσης, το ηλεκτρονικό ταχυδρομείο δεν μπορεί πλέον να χρησιμοποιηθεί.',
     'DELETE_TOPIC_DESCRIPTION': 'Διαγράφοντας το θέμα, όλα τα άρθρα σε αυτό θα διαγραφούν.',
     'EDIT_TOPIC_DESCRIPTION': 'Εδώ μπορείτε να αλλάξετε το όνομα, το εικονίδιο και το χρώμα του εικονιδίου του θέματος.',
     'ADD_ARTICLE_DESCRIPTION': 'Εδώ μπορείτε να προσθέσετε ένα άρθρο που θα είναι διαθέσιμο για κάθε χρήστη. Θα προστεθεί μέσα στην κατηγορία {κατηγορία}.',
     'LIST_ARTICLES_DESCRIPTION': 'Αυτή είναι μια λίστα με άρθρα που περιλαμβάνουν πληροφορίες σχετικά με τις υπηρεσίες μας.',
     'ADD_TOPIC_DESCRIPTION': 'Εδώ μπορείτε να προσθέσετε ένα θέμα που λειτουργεί ως κατηγορία για άρθρα.',
     'DELETE_ARTICLE_DESCRIPTION': 'Πρόκειται να διαγράψετε αυτό το άρθρο για πάντα.',
     'STAFF_MEMBERS_DESCRIPTION': 'Εδώ μπορείτε να δείτε ποιοι είναι οι υπάλληλοί σας.',
     'ADD_STAFF_DESCRIPTION': 'Εδώ μπορείτε να προσθέσετε μέλη του προσωπικού στις ομάδες σας.',
     'EDIT_STAFF_DESCRIPTION': 'Εδώ μπορείτε να επεξεργαστείτε πληροφορίες σχετικά με ένα μέλος του προσωπικού.',
     'MY_ACCOUNT_DESCRIPTION': 'Εδώ μπορείτε να επεξεργαστείτε πληροφορίες σχετικά με εσάς.',
     'DEPARTMENTS_DESCRIPTION': 'Ένα τμήμα είναι μια ομάδα όπου μπορούν να πάνε τα εισιτήρια. Χρησιμοποιούνται για την κατηγοριοποίηση των εισιτηρίων. Μπορείτε να τα αναθέσετε σε άλλα μέλη του προσωπικού.',
     'MAINTENANCE_MODE_DESCRIPTION': 'Το σύστημα υποστήριξης βρίσκεται σε κατάσταση συντήρησης, επομένως δεν είναι διαθέσιμο προς το παρόν. Θα επιστρέψουμε το συντομότερο δυνατό.',
     'EMAIL_TEMPLATES_DESCRIPTION': 'Εδώ μπορείτε να επεξεργαστείτε τα πρότυπα των μηνυμάτων ηλεκτρονικού ταχυδρομείου που θα αποστέλλονται στους χρήστες. Θυμηθείτε ότι τα διπλά άγκιστρα δείχνουν μια μεταβλητή τιμή. Για παράδειγμα, το \'όνομα \' αντιπροσωπεύει το όνομα του χρήστη',
     'SYSTEM_PREFERENCES_DESCRIPTION': 'Εδώ μπορείτε να επεξεργαστείτε τις προτιμήσεις του συστήματος.',
     'VERIFY_SUCCESS_DESCRIPTION': 'Ο χρήστης έχει επαληθευτεί σωστά. Μπορείτε να συνδεθείτε τώρα.',
     'VERIFY_FAILED_DESCRIPTION': 'Η επαλήθευση δεν μπορούσε να γίνει.',
     'STATISTICS_DESCRIPTION': 'Εδώ μπορείτε να δείτε στατιστικά στοιχεία σχετικά με εισιτήρια και εγγραφές.',
     'ADVANCED_SETTINGS_DESCRIPTION': 'Εδώ μπορείτε να αλλάξετε τις προηγμένες ρυθμίσεις του συστήματός σας. Προσέξτε, οι αλλαγές που κάνετε δεν μπορούν να αντιστραφούν.',
     'USER_SYSTEM_DISABLED': 'Το σύστημα χρήστη έχει απενεργοποιηθεί',
     'USER_SYSTEM_ENABLED': 'Το σύστημα χρήστη έχει ενεργοποιηθεί',
     'REGISTRATION_DISABLED': 'Η καταχώρηση έχει απενεργοποιηθεί',
     'REGISTRATION_ENABLED': 'Η εγγραφή έχει ενεργοποιηθεί',
     'ADD_API_KEY_DESCRIPTION': 'Εισαγάγετε το όνομα και θα δημιουργηθεί ένα κλειδί API εγγραφής.',
     'SIGN_UP_VIEW_DESCRIPTION': 'Εδώ μπορείτε να δημιουργήσετε λογαριασμό για το κέντρο υποστήριξης. Απαιτείται για την αποστολή εισιτηρίων και την προβολή της τεκμηρίωσης.',
     'EDIT_PROFILE_VIEW_DESCRIPTION': 'Εδώ μπορείτε να επεξεργαστείτε το χρήστη, αλλάζοντας την διεύθυνση ηλεκτρονικού ταχυδρομείου σας ή τον κωδικό πρόσβασης σας.',
     'ENABLE_USER_SYSTEM_DESCRIPTION': 'Ενεργοποίηση / απενεργοποίηση της χρήσης ενός συστήματος χρήστη. Εάν την απενεργοποιήσετε, όλοι οι χρήστες θα διαγραφούν αλλά τα εισιτήρια θα διατηρηθούν. Εάν την ενεργοποιήσετε, θα δημιουργηθούν οι χρήστες των υφιστάμενων εισιτηρίων.',
     'CSV_DESCRIPTION': 'Το αρχείο CSV πρέπει να έχει 3 στήλες: email, κωδικό πρόσβασης, όνομα. Δεν υπάρχει όριο στην καταμέτρηση των σειρών. Θα δημιουργηθεί ένας χρήστης ανά σειρά στο αρχείο.',
     'SMTP_SERVER_DESCRIPTION': 'Η διαμόρφωση του διακομιστή SMTP επιτρέπει στην εφαρμογή να στείλει μηνύματα. Αν δεν το ρυθμίσετε, δεν θα σταλούν μηνύματα ηλεκτρονικού ταχυδρομείου από το OpenSupport.',
     'ENABLE_USER_DESCRIPTION': 'Αυτή η ενέργεια επιτρέπει στο χρήστη να συνδεθεί και να δημιουργήσει εισιτήρια.',
     'DISABLE_USER_DESCRIPTION': 'Ο χρήστης θα απενεργοποιηθεί και δεν θα μπορέσει να συνδεθεί και να δημιουργήσει εισιτήρια.',
    'PRIVATE_RESPONSE_DESCRIPTION': 'Αυτή η απάντηση θα δει μόνο τα μέλη του προσωπικού',
    'PRIVATE_TOPIC_DESCRIPTION': 'Αυτό το θέμα θα προβληθεί μόνο από μέλη του προσωπικού',
    'PRIVATE_DEPARTMENT_DESCRIPTION': 'Αυτό το τμήμα θα δει μόνο τα μέλη του προσωπικού',

     //ERRORS
     'EMAIL_OR_PASSWORD': 'Λάθος ηλεκτρονική διεύθυνση ή κωδικός πρόσβασης',
     'EMAIL_NOT_EXIST': 'Αυτή η ηλεκτρονική διεύθυνση δεν υπάρχει',
     'ERROR_EMPTY': 'Μη έγκυρη τιμή',
     'ERROR_PASSWORD': 'Μη έγκυρος κωδικός πρόσβασης',
     'ERROR_NAME': 'Μη έγκυρο όνομα',
     'ERROR_TITLE': 'Μη έγκυρος τίτλος',
     'ERROR_EMAIL': 'Μη έγκυρη ηλεκτορνική διεύθυνση',
     'ERROR_CONTENT_SHORT': 'Πολύ μικρό περιεχόμενο.',
     'PASSWORD_NOT_MATCH': 'Ο κωδικός ασφαλείας σας (Password) δεν είναι ίδιος',
     'INVALID_RECOVER': 'Μη έγκυρα δεδομένα ανάκτησης',
     'TICKET_SENT_ERROR': 'Ένα σφάλμα παρουσιάστηκε ενώ προσπαθούσαμε να δημιουργήσουμε το εισιτήριο.',
     'TICKET_COMMENT_ERROR': 'Ένα σφάλμα παρουσιάστηκε ενώ προσπαθούσαμε να προσθέσουμε το σχόλιο.',
     'NO_PERMISSION': 'Δεν έχετε άδεια πρόσβασης σε αυτή τη σελίδα.',
     'INVALID_USER': 'Το αναγνωριστικό χρήστη δεν είναι έγκυρο',
     'ERROR_RETRIEVING_TICKETS': 'Ένα σφάλμα παρουσιάστηκε ενώ προσπαθούσαμε να ανακτήσουμε τα εισιτήρια.',
     'ERROR_RETRIEVING_USERS': 'Ένα σφάλμα παρουσιάστηκε ενώ προσπαθούσαμε να ανακτήσουμε τους χρήστες.',
     'ERROR_RETRIEVING_BAN_LIST': 'Ένα σφάλμα παρουσιάστηκε ενώ προσπαθούσαμε να ανακτήσουμε την λίστα των αποκλεισμένων διευθύνσεων ηλεκτρονικού ταχυδρομείου.',
     'ERROR_BANNING_EMAIL': 'Ένα σφάλμα παρουσιάστηκε ενώ προσπαθούσαμε να αποκλείσουμε την διεύθυνση ηλεκτρονικού ταχυδρομείου.',
     'ERROR_RETRIEVING_ARTICLES': 'Παρουσιάστηκε σφάλμα κατά την προσπάθεια ανάκτησης άρθρων.',
     'ERROR_LIST': 'Επιλέξτε τουλάχιστον ένα',
     'ERROR_URL': 'Μη έγκυρο URL',
     'UNVERIFIED_EMAIL': 'Η ηλεκτρονική διεύθυνση δεν έχει επιβεβαιωθεί ακόμα',
     'ERROR_UPDATING_SETTINGS': 'Παρουσιάστηκε σφάλμα κατά την προσπάθεια ενημέρωσης των ρυθμίσεων',
     'INVALID_EMAIL_OR_TICKET_NUMBER': 'Μη έγκυρη ηλεκτρονική διεύθυνση ή αριθμός εισιτηρίου',
     'INVALID_FILE': 'Μη έγκυρο αρχείο',
     'ERRORS_FOUND': 'Βρέθηκαν Σφάλματα',
     'ERROR_IMAGE_SIZE': 'Καμία εικόνα δεν μπορεί να έχει μέγεθος μεγαλύτερο από {size} MB',
     'USER_DISABLED': 'Αυτός ο λογαριασμός είναι απενεργοποιημένος.',
     'INVALID_SYNTAX': 'Μη έγκυρη σύνταξη.',
    'DEPARTMENT_PRIVATE_TICKETS': 'Αυτό το τμήμα έχει εισιτήρια που δημιουργούνται από μη προσωπικό και δεν μπορεί να είναι ιδιωτικό',

     //MESSAGES
     'SIGNUP_SUCCESS': 'Έχετε εγγραφεί με επιτυχία στο σύστημα υποστήριξης μας.',
     'TICKET_SENT': 'Το εισιτήριο δημιουργήθηκε επιτυχώς.',
     'VALID_RECOVER': 'Ο κωδικός ανακτήθηκε επιτυχώς',
     'EMAIL_EXISTS': 'Αυτή η ηλεκτρονική διεύθυνση υπάρχει ήδη',
     'ARE_YOU_SURE': 'Επιβεβαίωσε δράση',
     'EMAIL_WILL_CHANGE': 'Η τρέχουσα ηλεκτρονική διεύθυνση θα αλλαχτεί',
     'PASSWORD_WILL_CHANGE': 'Ο τρέχων κωδικός θα αλλάξει',
     'EMAIL_CHANGED': 'Η ηλεκτρονική διεύθυνση άλλαξε επιτυχώς',
     'PASSWORD_CHANGED': 'Ο κωδικός πρόσβασης άλλαξε επιτυχώς',
     'OLD_PASSWORD_INCORRECT': 'Ο παλιός κωδικός είναι λανθασμένος',
     'WILL_LOSE_CHANGES': 'Οι αλλαγές σας θα χαθούν.',
     'WILL_DELETE_CUSTOM_RESPONSE': 'Η προσαρμοσμένη απάντηση θα διαγραφεί.',
     'WILL_DELETE_DEPARTMENT': 'Το τμήμα θα διαγραφεί. Όλα τα εισιτήρια θα μεταφερθούν στο επιλεγμένο τμήμα.',
     'NO_STAFF_ASSIGNED': 'Κανένας υπάλληλος δεν έχει ανατεθεί σε αυτό το τμήμα.',
     'NO_DEPARTMENT_ASSIGNED': 'Κανένα τμήμα δεν σας έχει εκχωρηθεί.',
     'LEVEL_UPDATED': 'Το επίπεδο έχει ενημερωθεί με επιτυχία.',
     'DEPARTMENTS_UPDATED': 'Τα τμήματα ενημερώθηκαν με επιτυχία.',
     'FAILED_EDIT_STAFF': 'Παρουσιάστηκε σφάλμα κατά την προσπάθεια επεξεργασίας του μέλους του προσωπικού.',
     'EMAIL_BANNED_SUCCESSFULLY': 'H διεύθυνση ηλεκτρονικού ταχυδρομείου έχει αποκλειστεί επιτυχώς.',
     'WILL_DELETE_STAFF': 'Αυτό το μέλος του προσωπικού θα διαγραφεί και όλα τα εισιτήρια του θα διαγραφούν.',
     'WILL_RECOVER_EMAIL_TEMPLATE': 'Αυτό το πρότυπο ηλεκτρονικού ταχυδρομείου θα αναληφθεί σε κατάσταση αρχική με την αρχική γλώσσα.',
     'SUCCESS_IMPORTING_CSV_DESCRIPTION': 'Το αρχείο CSV έχει εισαχθεί επιτυχώς',
     'SUCCESS_DELETING_ALL_USERS': 'Οι χρήστες έχουν διαγραφεί επιτυχώς',

     'LAST_7_DAYS': 'Τελευταίες 7 μέρες',
     'LAST_30_DAYS': 'Τελευταίες 30 μέρες',
     'LAST_90_DAYS': 'Τελευταίες 90 μέρες',
     'LAST_365_DAYS': 'Τελευταίες 365 μέρες',

     'ACTIVITY_COMMENT_THIS': 'σχολίασε αυτό το εισιτήριο',
     'ACTIVITY_ASSIGN_THIS': 'ορίστηκε αυτό το εισιτήριο σε',
     'ACTIVITY_UN_ASSIGN_THIS': 'δεν ορίστηκε αυτό το εισιτήριο σε',
     'ACTIVITY_CLOSE_THIS': 'έκλεισε αυτό το εισιτήριο',
     'ACTIVITY_CREATE_TICKET_THIS': 'δημιουργήθηκε αυτό το εισιτήριο',
     'ACTIVITY_RE_OPEN_THIS': 'άνοιξε εκ νέου αυτό το εισιτήριο',
     'ACTIVITY_DEPARTMENT_CHANGED_THIS': 'άλλαξε το τμήμα αυτού του εισιτηρίου',
     'ACTIVITY_PRIORITY_CHANGED_THIS': 'αλλαγή της προτεραιότητας αυτού του εισιτηρίου',
     'DATE_PREFIX': 'ενεργό',
     'LEFT_EMPTY_DATABASE': 'Αφέθηκε κενό για αυτόματη δημιουργία βάσης δεδομένων',
     'DEFAULT_PORT': 'Αφήστε κενό για 3306 ως προεπιλογή',
     'REMEMBER_ME': 'Θυμήσου με',
     'EMAIL_LOWERCASE': 'ηλεκτρονική διεύθυνση',
     'PASSWORD_LOWERCASE': 'κωδικός πρόσβασης',
     'TEST_SMTP_CONNECTION': 'Δοκίμασε την SMTP σύνδεση',
     'SMTP_CONNECTION_SUCCESS': 'Τα SMTP διαπιστευτήρια είναι έγκυρα.',
     'SMTP_CONNECTION_ERROR': 'Δεν μπορεί να γίνει σύνδεση στον SMTP διακομιστή.'
 };
