export default {
    'WELCOME': 'Bienvenue',
    'TICKETS': 'Tickets',
    'ARTICLES': 'Articles',
    'ACCOUNT': 'Compte',
    'SUBMIT': 'Soumettre',
    'EMAIL': 'Email',
    'PASSWORD': 'Mot de passe',
    'REPEAT_PASSWORD': 'Répéter le mot de passe',
    'LOG_IN': 'S\'identifier',
    'SIGN_UP': 'S\'inscrire',
    'FORGOT_PASSWORD': 'Mot de passe oublié ?',
    'RECOVER_PASSWORD': 'Récupérer mot de passe',
    'RECOVER_SENT': 'Un e-mail contenant les instructions de récupération a été envoyé.',
    'NEW_EMAIL': 'Nouveau courriel',
    'FULL_NAME': 'Nom complet',
    'OLD_PASSWORD': 'Ancien mot de passe',
    'NEW_PASSWORD': 'Nouveau mot de passe',
    'REPEAT_NEW_PASSWORD': 'Répéter le nouveau mot de passe',
    'BACK_LOGIN_FORM': 'Retour au formulaire de connexion',
    'VIEW_ARTICLES': 'Voir les articles',
    'EDIT_PROFILE': 'Editer le profil',
    'CLOSE_SESSION': 'Fermer la session',
    'CREATE_TICKET': 'Créer un ticket',
    'TICKET_LIST': 'Liste des tickets',
    'SUPPORT_CENTER': 'Centre d\'assistance',
    'DEPARTMENT': 'Département',
    'AUTHOR': 'Auteur',
    'DATE': 'Date',
    'RESPOND': 'Répondre',
    'RESPOND_TICKET': 'Répondre au ticket',
    'CLOSE_TICKET': 'Fermer ticket',
    'DELETE_TICKET': 'Supprimer le ticket',
    'NO_ATTACHMENT': 'Aucune pièce jointe',
    'STAFF': 'Administrateur',
    'CUSTOMER': 'Client',
    'YES': 'Oui',
    'NO': 'Non',
    'CANCEL': 'Annuler',
    'MY_ACCOUNT': 'Mon compte',
    'DASHBOARD': 'Tableau de bord',
    'USERS': 'Utilisateurs',
    'SETTINGS': 'Paramètres',
    'STATISTICS': 'Statistiques',
    'LAST_ACTIVITY': 'Dernière Activité',
    'MY_TICKETS': 'Mes tickets',
    'NEW_TICKETS': 'Nouveaux tickets',
    'ALL_TICKETS': 'Tous les tickets',
    'CUSTOM_RESPONSES': 'Réponses personnalisées',
    'LIST_USERS': 'Voir les utilisateurs',
    'BAN_USERS': 'Bannir des utilisateurs',
    'LIST_ARTICLES': 'Voir les articles',
    'STAFF_MEMBERS': 'Membres de l\'administration',
    'DEPARTMENTS': 'Départements',
    'SYSTEM_PREFERENCES': 'Préférences du système',
    'ADVANCED_SETTINGS': 'Réglages avancés',
    'EMAIL_TEMPLATES': 'Modèles de courrier électronique',
    'FILTERS_CUSTOM_FIELDS': 'Filtres et champs personnalisés',
    'PRIORITY': 'Priorité',
    'NUMBER': 'Nombre',
    'HIGH': 'Haute',
    'MEDIUM': 'Moyenne',
    'LOW': 'Faible',
    'TITLE': 'Titre',
    'CONTENT': 'Contenu',
    'SAVE': 'Enregistrer',
    'DISCARD_CHANGES': 'Annuler les modifications',
    'DELETE': 'Supprimer',
    'LANGUAGE': 'Langue',
    'OWNER': 'Assigné à',
    'OWNED': 'Assigné à',
    'STATUS': 'Statut',
    'NONE': 'Aucun',
    'OPENED': 'Ouvert',
    'CLOSED': 'Fermé',
    'CLOSE': 'Fermer',
    'RE_OPEN': 'Réouvrir',
    'ASSIGN_TO_ME': 'Assignez-moi',
    'UN_ASSIGN': 'Désassigner',
    'VIEW_TICKET': 'Voir le ticket',
    'VIEW_TICKET_DESCRIPTION': 'Vérifiez l\'état de votre ticket en utilisant votre numéro de ticket et votre courriel.',
    'SELECT_CUSTOM_RESPONSE': 'Sélectionnez une réponse personnalisée ...',
    'WARNING': 'Attention',
    'INFO': 'Information',
    'ALL_DEPARTMENTS': 'Tous les départements',
    'EMAIL_BANNED': 'Email bannis',
    'UN_BAN': 'Désactiver le ban',
    'BAN_NEW_EMAIL': 'Bannir un nouveau courriel',
    'BAN_EMAIL': 'Bannir l\'email',
    'EDIT_EMAIL': 'Modifier l\'email',
    'EDIT_PASSWORD': 'Modifier le mot de passe',
    'CHANGE_EMAIL': 'Changer l\'e-mail',
    'CHANGE_PASSWORD': 'Changer le mot de passe',
    'NAME': 'Nom',
    'SIGNUP_DATE': 'Date d\'inscription',
    'SEARCH_USERS': 'Rechercher des utilisateurs ...',
    'SEARCH_EMAIL': 'Chercher un email...',
    'USER_VIEW_TITLE': 'Utilisateur #{userId}',
    'EDIT_TOPIC': 'Modifier le sujet',
    'ADD_TOPIC': 'Ajouter un sujet',
    'ICON': 'Icône',
    'COLOR': 'Couleur',
    'ADD_NEW_ARTICLE': 'Ajouter un nouvel article',
    'ADD_ARTICLE': 'Ajouter un article',
    'LAST_EDITED_IN': 'Dernière modification le {date}',
    'EDIT': 'Modifier',
    'NO_RESULTS': 'Aucun résultat',
    'DELETE_AND_BAN': 'Supprimer et bannir',
    'STAFF_LEVEL': 'Niveau d\'administration',
    'ASSIGNED': 'Assigné',
    'ASSIGNED_TICKETS': '{tickets} tickets assignés',
    'CLOSED_TICKETS': '{tickets} tickets fermés',
    'LAST_LOGIN': 'Dernière connexion',
    'ADD_NEW_STAFF': 'Ajouter un nouvel administrateur',
    'ADD_STAFF': 'Ajouter un administrateur',
    'LEVEL': 'Niveau',
    'LEVEL_1': 'Niveau 1 (Tickets)',
    'LEVEL_2': 'Niveau 2 (Tickets + Articles)',
    'LEVEL_3': 'Niveau 3 (Tickets + Articles + Administrateurs)',
    'LEVEL_1_DESCRIPTION': 'Ne peut que répondre aux tickets et gérer les utilisateurs.',
    'LEVEL_2_DESCRIPTION': 'En plus du niveau 1, peut créer ou modifier des articles et créer des réponses personnalisées.',
    'LEVEL_3_DESCRIPTION': 'En plus du niveau 2, peut créer ou modifier des membres de l\'administration et gérer l\'ensemble du système.',
    'UPDATE_EMAIL': 'Mettre à jour l\'email',
    'UPDATE_PASSWORD': 'Mettre à jour le mot de passe',
    'UPDATE_LEVEL': 'Mettre à jour le niveau',
    'UPDATE_DEPARTMENTS': 'Mettre à jour les départements',
    'EDIT_STAFF': 'Modifier le membre de l\'administration',
    'ADD_DEPARTMENT': 'Ajouter un département',
    'UPDATE_DEPARTMENT': 'Mettre à jour le département',
    'TRANSFER_TICKETS_TO': 'Transférer les tickets à',
    'COMMENTS': 'Commentaires',
    'DELETE_STAFF_MEMBER': 'Supprimer le membre de l\'administration',
    'MAINTENANCE_MODE': 'Mode de Maintenance',
    'MAINTENANCE_MODE_INFO': 'Désactive temporairement le système pour les utilisateurs normaux.',
    'RECOVER_DEFAULT': 'Récupération par défaut',
    'SUPPORT_CENTER_URL': 'URL du Centre de Support',
    'SUPPORT_CENTER_TITLE': 'Titre du Centre de Support',
    'SUPPORT_CENTER_LAYOUT': 'Disposition du Centre de Support',
    'DEFAULT_TIMEZONE': 'Fuseau horaire par défaut (GMT)',
    'NOREPLY_EMAIL': 'Adresse email "noreply"',
    'SMTP_USER': 'Utilisateur SMTP',
    'SMTP_SERVER': 'Serveur SMTP',
    'SMTP_PASSWORD': 'Mot de passe SMTP',
    'PORT': 'Port',
    'RECAPTCHA_PUBLIC_KEY': 'Clé publique Recaptcha',
    'RECAPTCHA_PRIVATE_KEY': 'Clé privée Recaptcha',
    'ALLOW_FILE_ATTACHMENTS': 'Autoriser les pièces jointes',
    'MAX_SIZE_MB': 'Taille Maximale (Ko)',
    'UPDATE_SETTINGS': 'Mettre à jour les paramètres',
    'DEFAULT_LANGUAGE': 'Langue par défaut',
    'SUPPORTED_LANGUAGES': 'Langues prises en charges',
    'SUPPORTED_LANGUAGES_INFO': 'Les langues prises en charge sont les langues dans lesquelles les tickets peuvent être écrits.',
    'ALLOWED_LANGUAGES': 'Langues autorisées',
    'ALLOWED_LANGUAGES_INFO': 'Les langues autorisées sont les langues qui peuvent être utilisées par un utilisateur.',
    'SETTINGS_UPDATED': 'Les paramètres ont été mis à jour',
    'ON': 'Activé',
    'OFF': 'Désactivé',
    'BOXED': 'Boîte',
    'FULL_WIDTH': 'Pleine largeur',
    'LOAD_MORE': 'Charger plus',
    'MY_NOTIFICATIONS': 'Mes notifications',
    'ALL_NOTIFICATIONS': 'Toutes les notifications',
    'VERIFY_SUCCESS': 'Utilisateur vérifié',
    'VERIFY_FAILED': 'Impossible de vérifier',
    'ENABLE_USER_SYSTEM': 'Utiliser le système utilisateur pour les clients',
    'ENABLE_USER_REGISTRATION': 'Activer l\'inscription des utilisateurs',
    'INCLUDE_USERS_VIA_CSV': 'Inclure des utilisateurs via un fichier CSV',
    'BACKUP_DATABASE': 'Base de données de sauvegarde',
    'DELETE_ALL_USERS': 'Supprimer tous les utilisateurs',
    'PLEASE_CONFIRM_PASSWORD': 'Veuillez confirmer votre mot de passe pour effectuer ces modifications.',
    'REGISTRATION_API_KEYS': 'Clés d\'authentification API',
    'NAME_OF_KEY': 'Nom de la clé',
    'KEY': 'Clé',
    'ADD_API_KEY': 'Ajouter une clé API',
    'NO_KEY_SELECTED': 'Aucune clé sélectionnée',
    'CHECK_TICKET': 'Vérifier le ticket',
    'ACTIVITY': 'Activité',
    'HOME': 'Accueil',
    'TICKET_NUMBER': 'Numéro de ticket',
    'NEXT': 'Suivant',
    'SUBJECT': 'Assujettir',
    'SEND_EMAIL_ON_NEW_TICKET': 'Envoyer un e-mail pour chaque nouveau ticket',
    'STAFF_UPDATED': 'Le membre du personnel a été mis à jour',
    'UPDATE': 'Mettre à jour',
    'NEVER': 'Jamais',
    'HIMSELF': 'lui-même',
    'ADD_USER': 'Ajouter un utilisateur',
    'UPLOAD_FILE': 'Téléverser un fichier',
    'PRIVATE': 'privé',
    'ENABLE_USER': 'Activer l\'utilisateur',
    'DISABLE_USER': 'Désactiver l\'utilisateur',
    'SHOW_CLOSED_TICKETS': 'Afficher les billets fermés',
    'IMAGE_HEADER_URL': 'URL de l\'en-tête de l\'image',

    'CHART_CREATE_TICKET': 'Tickets créés',
    'CHART_CLOSE': 'Tickets fermés',
    'CHART_SIGNUP': 'Inscriptions',
    'CHART_COMMENT': 'Réponses',
    'CHART_ASSIGN': 'Assignés',

    //ACTIVITIES
    'ACTIVITY_COMMENT': 'ticket commenté',
    'ACTIVITY_ASSIGN': 'ticket assigné',
    'ACTIVITY_UN_ASSIGN': 'ticket non assigné',
    'ACTIVITY_CLOSE': 'ticket fermé',
    'ACTIVITY_CREATE_TICKET': 'ticket créé',
    'ACTIVITY_RE_OPEN': 'ticket réouvert',
    'ACTIVITY_DEPARTMENT_CHANGED': 'a changé de département le ticket',
    'ACTIVITY_PRIORITY_CHANGED': 'a changé la priorité du ticket',

    'ACTIVITY_EDIT_SETTINGS': 'a modifié les paramètres',
    'ACTIVITY_SIGNUP': 's\'est inscrit',
    'ACTIVITY_ADD_TOPIC': 'a ajouté un sujet',
    'ACTIVITY_ADD_ARTICLE': 'a ajouté un article',
    'ACTIVITY_DELETE_TOPIC': 'a supprimé le sujet',
    'ACTIVITY_DELETE_ARTICLE': 'a supprimé un article',
    'ACTIVITY_EDIT_ARTICLE': 'a modifié un article',
    'ACTIVITY_ADD_STAFF': 'a ajouté un administrateur',
    'ACTIVITY_ADD_DEPARTMENT': 'a ajouté un département',
    'ACTIVITY_DELETE_DEPARTMENT': 'a supprimé le département',
    'ACTIVITY_EDIT_DEPARTMENT': 'a modifié le département',
    'ACTIVITY_ADD_CUSTOM_RESPONSE': 'a ajouté une réponse personnalisée',
    'ACTIVITY_DELETE_CUSTOM_RESPONSE': 'a supprimé une réponse personnalisée',
    'ACTIVITY_EDIT_CUSTOM_RESPONSE': 'a modifié une réponse personnalisée',
    'ACTIVITY_BAN_USER': 'a banni un utilisateur',
    'ACTIVITY_DELETE_USER': 'a supprimé un utilisateur',
    'ACTIVITY_UN_BAN_USER': 'a retiré le ban d\'un utilisateur',

    'SERVER_REQUIREMENTS': 'Configuration minimale du serveur',
    'DATABASE_CONFIGURATION': 'Configuration de la base de données',
    'ADMIN_SETUP': 'Configuration de l\'administrateur',
    'COMPLETED': 'Terminé',
    'INSTALL_HEADER_TITLE': 'Assistant d\'installation d\'OpenSupports',
    'INSTALL_HEADER_DESCRIPTION': 'Cet assistant vous aidera à configurer et à installer OpenSupports sur votre site Web',
    'SELECT_LANGUAGE': 'Choisir la langue',
    'REQUIREMENT': 'Condition requise',
    'VALUE': 'Valeur',
    'REFRESH': 'Actualiser',
    'USER_SYSTEM': 'Système utilisateur',
    'PREVIOUS': 'Précédent',
    'DATABASE_HOST': 'Serveur MySQL',
    'DATABASE_PORT': 'Port du serveur MySQL',
    'DATABASE_NAME': 'Nom de la base de données MySQL',
    'DATABASE_USER': 'Utilisateur MySQL',
    'DATABASE_PASSWORD': 'Mot de passe MySQL',
    'ADMIN_NAME': 'Nom du compte administrateur',
    'ADMIN_EMAIL': 'Courriel du compte administrateur',
    'ADMIN_PASSWORD': 'Mot de passe administrateur',
    'ADMIN_PASSWORD_DESCRIPTION': 'Ne perdez pas ce mot de passe. Il est nécessaire pour accéder au panel d\'administration. Vous pourrez le modifier ultérieurement.',
    'INSTALLATION_COMPLETED': 'Installation terminée.',
    'INSTALLATION_COMPLETED_DESCRIPTION': 'L\'installation d\'OpenSupports est terminée. Redirection vers le panel d\'administration...',

    'STEP_TITLE': 'Étape {current} of {total} - {title}',
    'STEP_1_DESCRIPTION': 'Sélectionnez la langue de votre choix pour l\'assistant d\'installation.',
    'STEP_2_DESCRIPTION': 'Voici les conditions requises pour exécuter OpenSupports. Assurez-vous que toutes les conditions requises soient satisfaites.',
    'STEP_3_DESCRIPTION': 'Veuillez remplir la configuration de la base de données MySQL.',
    'STEP_4_DESCRIPTION': 'Veuillez sélectionner les préférences de votre système utilisateur.',
    'STEP_5_DESCRIPTION': 'Sélectionnez vos préférences générales du système.',
    'STEP_6_DESCRIPTION': 'Veuillez configurer le compte administrateur.',
    'STEP_7_DESCRIPTION': 'L\'installation est terminée.',

    //VIEW DESCRIPTIONS
    'CREATE_TICKET_DESCRIPTION': 'Il s\'agit d\'un formulaire de création de tickets. Remplissez le formulaire et envoyez-nous vos questions / doutes / suggestions. Notre système de support répondra le plus rapidement possible.',
    'TICKET_LIST_DESCRIPTION': 'Vous trouverez ici une liste de tous les tickets que vous avez envoyés à notre équipe d\'assistance.',
    'TICKETS_DESCRIPTION': 'Envoyez un ticket via notre centre de support et recevez la réponse de vos doutes, suggestions et problèmes.',
    'ARTICLES_DESCRIPTION': 'Jetez un coup d\'oeil à nos articles sur les questions courantes, les guides et la documentation.',
    'ACCOUNT_DESCRIPTION': 'Tous vos tickets sont stockés dans le profil de votre compte. Gardez une trace de tous vos tickets que vous envoyez à notre équipe.',
    'SUPPORT_CENTER_DESCRIPTION': 'Bienvenue dans notre centre de support. Vous pouvez nous contacter via un système de tickets. Notre équipe d\'assistance répondra à vos tickets.',
    'CUSTOM_RESPONSES_DESCRIPTION': 'Les réponses personnalisées sont des réponses automatisées pour des problèmes courants',
    'MY_TICKETS_DESCRIPTION': 'Ici, vous pouvez afficher les tickets dont vous êtes responsable.',
    'NEW_TICKETS_DESCRIPTION': 'Ici, vous pouvez afficher tous les nouveaux tickets qui n\'ont pas été attribués.',
    'ALL_TICKETS_DESCRIPTION': 'Ici, vous pouvez afficher les tickets assignés aux départements auxquels vous appartenez.',
    'TICKET_VIEW_DESCRIPTION': 'Ce ticket a été envoyé par un client. Ici vous pouvez répondre ou assigner ce ticket',
    'BAN_USERS_DESCRIPTION': 'Ici, vous pouvez voir la liste des e-mails qui ont été bannis. Vous pouvez en ajouter de nouveaux ou en supprimer de la liste.',
    'LIST_USERS_DESCRIPTION': 'Il s\'agit de la liste des utilisateurs enregistrés sur cette plate-forme. Vous pouvez rechercher quelqu\'un en particulier, le supprimer ou le bannir.',
    'USER_VIEW_DESCRIPTION': 'Ici vous pouvez trouver toutes les informations sur un utilisateur et tous les tickets envoyés par cet utilisateur. Vous pouvez également le supprimer ou le bannir.',
    'DELETE_USER_DESCRIPTION': 'L\'utilisateur ne sera plus en mesure de se connecter et tous ses tickets seront effacés. En outre, le courrier électronique ne pourra plus être utilisé.',
    'DELETE_TOPIC_DESCRIPTION': 'En supprimant le sujet, tous les articles correspondants seront effacés.',
    'EDIT_TOPIC_DESCRIPTION': 'Ici, vous pouvez changer le nom, l\'icône et la couleur de l\'icône du sujet.',
    'ADD_ARTICLE_DESCRIPTION': 'Ici vous pouvez ajouter un article qui sera disponible pour tous les utilisateurs. Il sera ajouté à l\'intérieur de la catégorie {category}.',
    'LIST_ARTICLES_DESCRIPTION': 'Il s\'agit d\'une liste d\'articles comprenant des informations sur nos services.',
    'ADD_TOPIC_DESCRIPTION': 'Ici, vous pouvez ajouter un sujet qui fonctionne comme une catégorie pour les articles.',
    'DELETE_ARTICLE_DESCRIPTION': 'Vous êtes sur le point de supprimer cet article définitivement.',
    'STAFF_MEMBERS_DESCRIPTION': 'Ici, vous pouvez voir qui sont vos membres de l\'administation.',
    'ADD_STAFF_DESCRIPTION': 'Ici, vous pouvez ajouter des membres de l\'administration à vos équipes.',
    'EDIT_STAFF_DESCRIPTION': 'Ici, vous pouvez modifier des informations sur un membre de l\'administration.',
    'MY_ACCOUNT_DESCRIPTION': 'Ici, vous pouvez modifier des informations vous concernant.',
    'DEPARTMENTS_DESCRIPTION': 'Un département est un groupe où les tickets peuvent être assignés. Ils sont utilisés pour catégoriser les tickets. Vous pouvez les assigner à d\'autres administrateurs.',
    'MAINTENANCE_MODE_DESCRIPTION': 'Le système de support est en mode maintenance, donc indisponible pour le moment. Nous reviendrons dès que possible.',
    'EMAIL_TEMPLATES_DESCRIPTION': 'Ici, vous pouvez modifier les modèles des e-mails qui seront envoyés aux utilisateurs. N\'oubliez pas que les doubles accolades indiquent une valeur variable. Par exemple, \'nom\' représente le nom de l\'utilisateur.',
    'SYSTEM_PREFERENCES_DESCRIPTION': 'Ici, vous pouvez modifier les préférences du système.',
    'VERIFY_SUCCESS_DESCRIPTION': 'Votre utilisateur a été correctement vérifié. Vous pouvez vous connecter maintenant.',
    'VERIFY_FAILED_DESCRIPTION': 'La vérification n\'a pas pu être effectuée.',
    'STATISTICS_DESCRIPTION': 'Ici, vous pouvez afficher les statistiques relatives aux tickets et inscriptions.',
    'ADVANCED_SETTINGS_DESCRIPTION': 'Ici, vous pouvez modifier les paramètres avancés de votre système. Attention, les modifications que vous effectuez sont irréversibles.',
    'USER_SYSTEM_DISABLED': 'Le système utilisateur a été désactivé',
    'USER_SYSTEM_ENABLED': 'Le système utilisateur a été activé',
    'REGISTRATION_DISABLED': 'L\'inscription a été désactivé',
    'REGISTRATION_ENABLED': 'L\'inscription a été activé',
    'ADD_API_KEY_DESCRIPTION': 'Insérer le nom et générer une clé api d\'authentification.',
    'SIGN_UP_VIEW_DESCRIPTION': 'Ici, vous pouvez créer un compte pour notre centre de support. Il est nécessaire pour soumettre des tickets et voir la documentation.',
    'EDIT_PROFILE_VIEW_DESCRIPTION': 'Ici, vous pouvez modifier votre utilisateur en changeant votre adresse e-mail ou votre mot de passe.',
    'ENABLE_USER_SYSTEM_DESCRIPTION': 'Activer / désactiver l\'utilisation d\'un système utilisateur. Si vous le désactivez, tous les utilisateurs seront supprimés, mais les tickets seront conservés. Si vous l\'activez, les utilisateurs des tickets existants seront créés.',
    'CSV_DESCRIPTION': 'Le fichier CSV doit comporter 3 colonnes: email, mot de passe, nom. Il n\'y a pas de limite dans le nombre de lignes. Il sera créé un utilisateur par ligne dans le fichier.',
    'SMTP_SERVER_DESCRIPTION': 'La configuration du serveur SMTP permet à l\'application d\'envoyer des mails. Si vous ne le configurez pas, aucun service d\'email ne sera envoyé par OpenSupports.',
    'ENABLE_USER_DESCRIPTION': 'Cette action permet à l\'utilisateur de se connecter et de créer des tickets.',
    'DISABLE_USER_DESCRIPTION': 'L\'utilisateur sera désactivé et ne pourra pas se connecter et créer des tickets.',
    'PRIVATE_RESPONSE_DESCRIPTION': 'Cette réponse ne sera vue que par les membres du personnel',
    'PRIVATE_TOPIC_DESCRIPTION': 'Ce sujet ne sera vu que par les membres du personnel',
    'PRIVATE_DEPARTMENT_DESCRIPTION': 'Ce département ne sera vu que par les membres du personnel',

    //ERRORS
    'EMAIL_OR_PASSWORD': 'E-mail ou mot de passe invalide',
    'EMAIL_NOT_EXIST': 'Le courriel n\'existe pas',
    'ERROR_EMPTY': 'Valeur invalide',
    'ERROR_PASSWORD': 'Mot de passe incorrect',
    'ERROR_NAME': 'Nom incorrect',
    'ERROR_TITLE': 'Titre incorrect',
    'ERROR_EMAIL': 'Email invalide',
    'ERROR_CONTENT_SHORT': 'Contenu trop court',
    'PASSWORD_NOT_MATCH': 'Le mot de passe ne correspond pas',
    'INVALID_RECOVER': 'Données de récupération non valides',
    'TICKET_SENT_ERROR': 'Une erreur s\'est produite lors de la création du ticket.',
    'TICKET_COMMENT_ERROR': 'Une erreur s\'est produite lors de l\'ajout du commentaire.',
    'NO_PERMISSION': 'Vous n\'êtes pas autorisé à accéder à cette page.',
    'INVALID_USER': 'L\'identifiant d\'utilisateur n\'est pas valide',
    'ERROR_RETRIEVING_TICKETS': 'Une erreur s\'est produite lors de la récupération des tickets.',
    'ERROR_RETRIEVING_USERS': 'Une erreur s\'est produite lors de la récupération des utilisateurs.',
    'ERROR_RETRIEVING_BAN_LIST': 'Une erreur s\'est produite lors de la récupération de la liste des e-mails bannis.',
    'ERROR_BANNING_EMAIL': 'Une erreur s\'est produite lors de la tentative d\'interdiction du courrier électronique.',
    'ERROR_RETRIEVING_ARTICLES': 'Une erreur s\'est produite lors de la récupération des articles.',
    'ERROR_LIST': 'Sélectionnez-en au moins un',
    'ERROR_URL': 'URL invalide',
    'UNVERIFIED_EMAIL': 'Le courriel n\'a pas encore été validé',
    'ERROR_UPDATING_SETTINGS': 'Une erreur s\'est produite lors de la mise à jour des paramètres',
    'INVALID_EMAIL_OR_TICKET_NUMBER': 'Numéro de courriel ou de ticket invalide',
    'INVALID_FILE': 'Fichier invalide',
    'ERRORS_FOUND': 'Des erreurs sont survenues',
    'ERROR_IMAGE_SIZE': 'Aucune image ne peut avoir une taille supérieure à {size} MB',
    'USER_DISABLED': 'Ce compte est désactivé.',
    'INVALID_SYNTAX': 'Syntaxe invalide.',
    'DEPARTMENT_PRIVATE_TICKETS': 'Ce service a des tickets créés par des non-membres du personnel et il ne peut pas être privé',

    //MESSAGES
    'SIGNUP_SUCCESS': 'Vous êtes inscrit avec succès dans notre système de support.',
    'TICKET_SENT': 'Le ticket a été créé avec succès.',
    'VALID_RECOVER': 'Mot de passe récupéré avec succès',
    'EMAIL_EXISTS': 'L\'email existe déjà',
    'ARE_YOU_SURE': 'Êtes-vous sûr ?',
    'EMAIL_WILL_CHANGE': 'L\'e-mail actuel sera modifié',
    'PASSWORD_WILL_CHANGE': 'Le mot de passe actuel sera modifié',
    'EMAIL_CHANGED': 'Le courrier électronique a été modifié avec succès',
    'PASSWORD_CHANGED': 'Le mot de passe a été modifié avec succès',
    'OLD_PASSWORD_INCORRECT': 'Ancien mot de passe incorrect',
    'WILL_LOSE_CHANGES': 'Vous n\'avez pas enregistrés vos modifications. Ces dernières seront perdues.',
    'WILL_DELETE_CUSTOM_RESPONSE': 'La réponse personnalisée sera supprimée.',
    'WILL_DELETE_DEPARTMENT': 'Le département sera supprimé. Tous les tickets seront transférés au département sélectionné.',
    'NO_STAFF_ASSIGNED': 'Aucun membre de l\'administration n\'est affecté à ce service.',
    'NO_DEPARTMENT_ASSIGNED': 'Aucun service de tickets ne vous est assigné.',
    'LEVEL_UPDATED': 'Le niveau a été mis à jour avec succès.',
    'DEPARTMENTS_UPDATED': 'Les départements ont été mis à jour avec succès.',
    'FAILED_EDIT_STAFF': 'Une erreur s\'est produite lors de la tentative de modification de l\'administrateur.',
    'EMAIL_BANNED_SUCCESSFULLY': 'Le courriel a été banni avec succès',
    'WILL_DELETE_STAFF': 'Cet administrateur sera supprimé et tous ses tickets ne seront plus assignés.',
    'WILL_RECOVER_EMAIL_TEMPLATE': 'Ce modèle de courrier électronique sera réinitialisé à sa valeur par défaut pour cette langue.',
    'SUCCESS_IMPORTING_CSV_DESCRIPTION': 'Le fichier CSV a été importé avec succès',
    'SUCCESS_DELETING_ALL_USERS': 'Les utilisateurs ont été supprimés avec succès',

    'LAST_7_DAYS': 'Les 7 derniers jours',
    'LAST_30_DAYS': 'Les 30 derniers jours',
    'LAST_90_DAYS': 'Les 90 derniers jours',
    'LAST_365_DAYS': 'Les 365 derniers jours',

    'ACTIVITY_COMMENT_THIS': 'a commenté ce ticket',
    'ACTIVITY_ASSIGN_THIS': 'a assigné ce ticket à',
    'ACTIVITY_UN_ASSIGN_THIS': 'a supprimé l\'assignation de ce ticket à',
    'ACTIVITY_CLOSE_THIS': 'a fermé ce ticket',
    'ACTIVITY_CREATE_TICKET_THIS': 'a créé ce ticket',
    'ACTIVITY_RE_OPEN_THIS': 'a réouvert ce ticket',
    'ACTIVITY_DEPARTMENT_CHANGED_THIS': 'a changé le département de ce ticket pour',
    'ACTIVITY_PRIORITY_CHANGED_THIS': 'a changé la priorité de ce ticket pour',
    'DATE_PREFIX': 'le',
    'LEFT_EMPTY_DATABASE': 'Laisser vide pour la création automatique de la base de données',
    'REMEMBER_ME': 'Se souvenir de moi',
    'EMAIL_LOWERCASE': 'email',
    'DEFAULT_PORT': 'Laisser vide pour 3306 par défaut',
    'PASSWORD_LOWERCASE': 'mot de passe',
    'TEST_SMTP_CONNECTION': 'Test de connexion SMTP',
    'SMTP_CONNECTION_SUCCESS': 'Les informations d\'identification SMTP sont valides.',
    'SMTP_CONNECTION_ERROR': 'Ne peut pas se connecter au serveur SMTP.'
};
